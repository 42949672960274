import { IEstimate } from "types/booking.type";
import styles from "./estimateActionSection.module.scss";
import Button from "components/Button/Button";
import { Close, Send } from "@mui/icons-material";
import { useMutation } from "react-query";
import {
  sendEstimateCanceledEmailMutation,
  sendEstimateSentEmailMutation,
} from "requests/notification";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "utils/toast.options";
import { toast } from "react-toastify";
import { streamFile } from "requests/file";
import { updateEstimate } from "requests/estimate";
import CancelEstimateReasonModal from "components/modals/cancelEstimateReasonModal/cancelEstimateReasonModal";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import { useState } from "react";

interface EstimateActionSectionProps {
  estimate: IEstimate;
  onEstimateChange: (estimate: IEstimate) => void;
  fileList: File[];
}

export default function EstimateActionSection({
  estimate,
  fileList,
  onEstimateChange,
}: EstimateActionSectionProps) {
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const updateEstimateMutation = useMutation(updateEstimate, {
    onSuccess: (data) => {
      if (!data) {
        return;
      }
      const { estimate } = data as { estimate: IEstimate };
      onEstimateChange({ ...estimate, state: estimate.state });
    },
    onError: () => {
      toast.error(
        "Un problème est survenu lors du changement de status.",
        TOAST_ERROR_OPTIONS
      );
    },
  });
  const sendEstimateCanceledEmail = useMutation(
    sendEstimateCanceledEmailMutation,
    {
      onSuccess: () => {
        toast.success(
          `Estimation annulée avec succès à ${estimate.contact.email}.`,
          TOAST_SUCCESS_OPTIONS
        );
      },
      onError: () => {
        toast.error(
          "Un problème est survenu lors de l'envoi du mail.",
          TOAST_ERROR_OPTIONS
        );
      },
    }
  );
  const sendEstimateSentEmail = useMutation(sendEstimateSentEmailMutation, {
    onSuccess: () => {
      toast.success(
        `Estimation envoyée avec succès à ${estimate.contact.email}.`,
        TOAST_SUCCESS_OPTIONS
      );
    },
    onError: () => {
      toast.error(
        "Un problème est survenu lors de l'envoi du mail.",
        TOAST_ERROR_OPTIONS
      );
    },
  });

  if (!estimate.contact.email || !estimate.trackId) {
    return null;
  }

  return (
    <>
      <Button
        isLoading={sendEstimateSentEmail.isLoading}
        onClick={handleSendEstimate}
      >
        Envoyer l'estimation <Send className={styles["send-icon"]} />
      </Button>
      <Button
        className={styles["non-feasible-button"]}
        isLoading={sendEstimateCanceledEmail.isLoading}
        onClick={handleOpenCancelationModal}
      >
        Non faisable <Send className={styles["send-icon"]} />
      </Button>
      <Button
        className={styles["cancel-button"]}
        isLoading={updateEstimateMutation.isLoading}
        onClick={handleSendCancelation}
      >
        Annulation client <Close className={styles["send-icon"]} />
      </Button>
      <ModalContainer
        isOpen={openCancelModal}
        onCancel={handleCloseCancelationModal}
        width="fit"
        height="fit"
      >
        <CancelEstimateReasonModal onSubmit={handleSendNonFeasible} />
      </ModalContainer>
    </>
  );

  function handleCloseCancelationModal() {
    setOpenCancelModal(false);
  }

  function handleOpenCancelationModal() {
    setOpenCancelModal(true);
  }

  async function handleSendNonFeasible(reason: string) {
    if (!reason || reason.length < 1) {
      toast.error(
        "Veuillez entrer une explication pour l'annulation.",
        TOAST_ERROR_OPTIONS
      );
      return;
    }
    sendEstimateCanceledEmail.mutate({
      email: estimate.contact.email!,
      trackId: estimate.trackId!,
      reason,
    });
    updateEstimateMutation.mutate({
      ...estimate,
      state: "NON_FEASIBLE",
    });
    handleCloseCancelationModal();
  }

  async function handleSendEstimate() {
    sendEstimateSentEmail.mutate({
      email: estimate.contact.email!,
      trackId: estimate.trackId!,
    });
    let imagesList = await submitFiles("_estimate.");
    updateEstimateMutation.mutate({
      ...estimate,
      imagesList,
      state: "ESTIMATE_SENT",
    });
  }

  async function handleSendCancelation() {
    updateEstimateMutation.mutate({
      ...estimate,
      state: "ESTIMATE_CANCELED",
    });
    // setIsLoading(true);
    // try {
    //   await updateEstimate({
    //     ...formValues,
    //     state: "ESTIMATE_CANCELED",
    //   });
    //   setFormValues((prev) => ({ ...prev, state: "ESTIMATE_CANCELED" }));
    // } catch (error) {
    //   toast.error(
    //     "Un problème est survenu lors du changement de status.",
    //     TOAST_ERROR_OPTIONS
    //   );
    // } finally {
    //   setIsLoading(false);
    //   setConfirmModalIsOpen(false);
    // }
  }

  async function submitFiles(slug: string) {
    try {
      let imagesList: string[] = estimate.imagesList || [];
      for (let file of fileList) {
        const response = await streamFile(slug, file);
        imagesList.push(response.message);
      }
      return imagesList;
    } catch (error) {
      toast.error(
        "Un problème est survenu lors de l'envoi de l'image.",
        TOAST_ERROR_OPTIONS
      );
      return [];
    }
  }
}
