import { DropOffForm, DropOffProductForm } from "types/logistic.types";
import styles from "./inStoreDropOff.module.scss";
import { useEffect, useMemo, useState } from "react";
import { INITIAL_DROPOFF } from "utils/logistic.init";
import ProductListSection from "./ProductListSection/ProductListSection";
import ProductFormSection from "./ProductFormSection/ProductFormSection";
import useLocalStorage from "hooks/useLocalStorage";
import PriceSummarySection from "./PriceSummarySection/PriceSummarySection";
import AddOperationAndCrossSellSection from "./AddOperationAndCrossSellSection/AddOperationAndCrossSellSection";
import ActionSection from "./ActionSection/ActionSection";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import NewOrderModal from "./modals/NewOrderModal/NewOrderModal";
import ShippingSection from "./ShippingSection/ShippingSection";
import { deleteFile } from "requests/file";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "utils/toast.options";
import { useSearchParams } from "react-router-dom";
import { areDropOffFormsEqual } from "utils/dropOff.utils";

interface InStoreDropOffProps {
  defaultDropOff?: DropOffForm;
}

export default function InStoreDropOff({
  defaultDropOff,
}: InStoreDropOffProps) {
  const [searchParams] = useSearchParams();
  const nonDropOff = (searchParams.get("type") || "") === "non-drop-off";
  const [openResumeDropOffModal, setOpenResumeDropOffModal] =
    useState<boolean>(false);
  const [dropOffForm, setDropOffForm] = useLocalStorage<DropOffForm>(
    "drop-off",
    { ...INITIAL_DROPOFF, nonDropOff }
  ) as [DropOffForm, React.Dispatch<DropOffForm>];
  const deleteImageMutation = useMutation(deleteFile, {
    onError() {
      toast.error(
        "Erreur lors de la suppression de l'image.",
        TOAST_ERROR_OPTIONS
      );
    },
  });

  useEffect(() => {
    handleResumeOrder();
  }, []);

  const selectedProduct = useMemo(() => {
    return dropOffForm.productList.find((p) => p.selected) || null;
  }, [dropOffForm.productList]);

  return (
    <>
      <div className={styles["in-store-drop-off-container"]}>
        <ProductListSection
          dropOff={dropOffForm}
          onDropOffChange={handleDropOffChange}
        />
        <div className={styles["in-store-drop-off-body"]}>
          <ProductFormSection
            className={styles["in-store-drop-off-left"]}
            dropOff={dropOffForm}
            product={selectedProduct}
            onProductChange={handleProductChange}
          />
          <div className={styles["in-store-drop-off-right"]}>
            <PriceSummarySection
              dropOffForm={dropOffForm}
              selectedProduct={selectedProduct}
            />
            <AddOperationAndCrossSellSection
              dropOff={dropOffForm}
              product={selectedProduct}
              onDropOffChange={handleDropOffChange}
            />
          </div>
        </div>
        <ShippingSection
          dropOff={dropOffForm}
          onDropOffChange={handleDropOffChange}
        />
        <ActionSection
          dropOff={dropOffForm}
          onDropOffChange={handleDropOffChange}
        />
      </div>
      <ModalContainer
        width="narrow"
        height="fit"
        isOpen={openResumeDropOffModal}
        onCancel={handleCloseModal}
      >
        <NewOrderModal onCloseModal={handleCloseModal} onReset={handleReset} />
      </ModalContainer>
    </>
  );

  function handleResumeOrder() {
    if (defaultDropOff) {
      setOpenResumeDropOffModal(
        !areDropOffFormsEqual({ ...defaultDropOff, nonDropOff }, dropOffForm)
      );
    } else {
      setOpenResumeDropOffModal(
        !areDropOffFormsEqual({ ...INITIAL_DROPOFF, nonDropOff }, dropOffForm)
      );
    }
  }

  async function handleReset() {
    for (let product of dropOffForm.productList) {
      for (let image of product.incomingPicturesList) {
        deleteImageMutation.mutate(image);
      }
    }
    if (defaultDropOff) {
      setDropOffForm({ ...defaultDropOff, nonDropOff });
    } else {
      setDropOffForm({ ...INITIAL_DROPOFF, nonDropOff });
    }
    setOpenResumeDropOffModal(false);
  }

  function handleCloseModal() {
    setOpenResumeDropOffModal(false);
  }

  function handleProductChange(targetProduct: DropOffProductForm) {
    setDropOffForm({
      ...dropOffForm,
      productList: dropOffForm.productList.map((p) =>
        targetProduct.id === p.id ? targetProduct : p
      ),
    });
  }

  function handleDropOffChange(dropOff: DropOffForm) {
    setDropOffForm(dropOff);
  }
}
