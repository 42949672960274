import { useQuery } from "react-query";
import { IOperation } from "../../../../../types/accounting.types";
import { OPERTATION_TYPE } from "../../../../../utils/logistic.init";
import {
  REFASHION_OPERATIONS,
  REFASHION_OPERATIONS_PRICE,
} from "../../../../../utils/operation.init";
import Select from "../../../../Select/Select";
import Input from "../../../../inputs/Input/Input";
import AutoCompleteOperationInput from "./autoCompleteOperationInput/AutoCompleteOperationInput";
import style from "./operationForm.module.scss";
import { fetchOperationsList } from "../../../../../requests/operation";
import { useState } from "react";

interface OperationFormProps {
  operationForm: IOperation;
  setOperationForm: (operationForm: IOperation | null) => void;
  handleValidateOperation: () => void;
}

export default function OperationForm({
  operationForm,
  setOperationForm,
  handleValidateOperation,
}: OperationFormProps) {
  const [priceIsDisabled, setPriceIsDisabled] = useState<boolean>(false);
  const { data } = useQuery(
    ["autocomplete-operation-list", operationForm],
    () => fetchOperationsList(formatedQuery(), true)
  );

  return (
    <div className={style["operation-form"]}>
      <div>
        <div className={style["input-line"]}>
          <AutoCompleteOperationInput
            autoCompleteOperationsList={data?.operations}
            operationForm={operationForm}
            setOperationForm={setOperationForm}
            setPriceIsDisabled={setPriceIsDisabled}
          />
          <Input
            className={style["input-container"]}
            disabled={priceIsDisabled}
            label="Prix"
            name="price"
            type="number"
            value={operationForm.price || 0}
            onChange={({ target }) =>
              setOperationForm({
                ...operationForm,
                price: parseFloat(target.value) * 100,
              })
            }
          />
        </div>
        <div className={style["input-line"]}>
          <div className={style["select-container"]}>
            <label>Type d'opération</label>
            <Select
              optionsList={OPERTATION_TYPE}
              value={operationForm.operationType || ""}
              setValue={(value) =>
                setOperationForm({
                  ...operationForm,
                  operationType: value,
                })
              }
              doTranslate
              placeholder={"Sélectionné un groupe d'article..."}
            />
          </div>
          <div className={style["select-container"]}>
            <label>Bonus réparation</label>
            <Select
              optionsList={REFASHION_OPERATIONS}
              value={operationForm?.refashionOperation || ""}
              setValue={(value) =>
                setOperationForm({
                  ...operationForm,
                  refashionOperation: value,
                  refashionDiscount:
                    REFASHION_OPERATIONS_PRICE.find(
                      (p) => p.refashionOperation === value
                    )?.refashionDiscount || 0,
                })
              }
              doTranslate
              placeholder={"Sélectionné un bonus réparation..."}
            />
          </div>
        </div>
        <div className={style["operation-form-footer"]}>
          <div
            className={style["validate-button"]}
            onClick={handleValidateOperation}
          >
            Valider opération
          </div>
          <div className={style["delete-button"]} onClick={handleCancel}>
            Annuler
          </div>
        </div>
      </div>
    </div>
  );

  function handleCancel() {
    setOperationForm(null);
  }

  function formatedQuery() {
    return `operationGroup=${operationForm.operationGroup}&productGroup=${operationForm.productGroup}`;
  }
}
