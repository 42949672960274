import { useParams } from "react-router-dom";
import { getUser } from "../../../requests/user";
import Create from "../create/create";
import { useQuery } from "react-query";
import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import DataError from "components/errors/DataError/DataError";

export default function Edit() {
  const { id } = useParams();
  const { data, isFetching } = useQuery(
    ["shipping-method", id],
    () => getUser(id),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(id),
    }
  );

  if (isFetching) {
    return <PictureLoadingSpinner />;
  }

  if (!data) {
    return <DataError error="user" />;
  }

  return <Create data={data} />;
}
