import { IShipping } from "../../../../types/shipping.types";
import style from "./externalShippingModal.module.scss";
import { fetchShippingList } from "../../../../requests/shipping";
import { useQuery } from "react-query";
import { IOpenModal } from "../..";
import ExternalShippingItem from "./externalShippingItem";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../../../components/loadingSpinner/loadingSpinner";

export default function ExternalShippingModal({
  openModal,
}: {
  openModal: IOpenModal;
}) {
  const [chronopostDirection, setChronopostDirection] =
    useState<string>("OUTGOING");
  const { data, isFetching, error, refetch } = useQuery(
    "shippingList",
    () => fetchShippingList(formatedQuery()),
    { refetchOnWindowFocus: false }
  );

  const chronoNumber =
    data &&
    data.shippingsList.filter(
      (value: IShipping) => value.direction === "OUTGOING"
    ).length;
  const EsdNumber =
    data &&
    data.shippingsList.filter(
      (value: IShipping) =>
        value.direction === "INCOMING" && value.shippingSourceType === "DIRECT"
    ).length;

  useEffect(() => {
    !isFetching && chronoNumber === 0
      ? setChronopostDirection("INCOMING")
      : setChronopostDirection("OUTGOING");
  }, [chronoNumber, EsdNumber]);

  return (
    <div className={style["modal"]}>
      {!isFetching ? (
        <>
          {data && (
            <div className={style["chronopost-list"]}>
              <div className={style["title"]}>
                {new Date(openModal.date)
                  .toLocaleDateString("fr-FR", {
                    day: "numeric",
                    weekday: "long",
                    month: "long",
                    year: "numeric",
                  })
                  .toUpperCase()}
              </div>
              <div className={style["chronopost-tab-container"]}>
                <div
                  className={`${style["chronopost-tab"]} ${
                    chronopostDirection === "OUTGOING" &&
                    style["chronopost-tab-selected"]
                  }`}
                  onClick={() => setChronopostDirection("OUTGOING")}
                >
                  {`Chronopost (${isFetching ? "-" : chronoNumber})`}
                </div>
                <div
                  className={`${style["chronopost-tab"]} ${
                    chronopostDirection === "INCOMING"
                      ? style["chronopost-tab-selected"]
                      : ""
                  }`}
                  onClick={() => setChronopostDirection("INCOMING")}
                >
                  {`ESD (${isFetching ? "-" : EsdNumber})`}
                </div>
              </div>
              {!isFetching ? (
                <>
                  {data.shippingsList.length > 0 ? (
                    data.shippingsList
                      ?.filter(
                        (shp) =>
                          shp.state === "PENDING" || shp.state === "DRAFT"
                      )
                      .map((value: IShipping, key: number) => {
                        return (
                          <div key={key}>
                            <ExternalShippingItem
                              value={value}
                              refetch={refetch}
                            />
                            {key < data.shippingsList.length - 1 && <hr />}
                          </div>
                        );
                      })
                  ) : (
                    <div className={style["empty-list-message"]}>
                      {`aucune livraison ${
                        chronopostDirection === "INCOMING"
                          ? "ESD (enlèvement sur demande)"
                          : "chronopost"
                      }...`}
                    </div>
                  )}
                </>
              ) : (
                <div className={style["loading"]}>
                  <LoadingSpinner color={"gold"} />
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <div className={style["loading-spinner-container"]}>
          <LoadingSpinner color="gold" />
        </div>
      )}
    </div>
  );

  function formatedQuery() {
    return `?period.start=${new Date(
      openModal.date
    ).toISOString()}&period.start=${new Date(
      openModal.date
    ).toISOString()}&shippingMethod.shippingService=CHRONOPOST-2SHOP&direction=OUTGOING`;
  }
}
