import styles from "./item.module.scss";
import { Link } from "react-router-dom";
import { IOperation, OperationForm } from "../../../types/accounting.types";
import translate from "../../../utils/translation";
import {
  Delete,
  Edit,
  Visibility,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import Select from "../../../components/Select/Select";
import { OPERTATION_TYPE } from "../../../utils/logistic.init";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import { toast } from "react-toastify";
import { updateOperation } from "../../../requests/operation";
import CenterCell from "../../../components/CenterCell/CenterCell";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import ProductGroupLogo from "../../../components/ProductGroupLogo/ProductGroupLogo";
import Price from "../../../components/Price/Price";
import { useMutation } from "react-query";
import clsx from "clsx";
import { isDesktop } from "react-device-detect";

interface ItemProps {
  data: OperationForm;
  refetch: () => void;
  onDelete: (id: string) => void;
}

export default function Item({ data, refetch, onDelete }: ItemProps) {
  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Edit />,
      name: "Modifier",
      to: `/operation-b2c/edit/${data.slug}`,
    },
    {
      icon: <Delete />,
      name: "Supprimer",
      askConfirmation: true,
      onClick: () => handleDelete(),
    },
  ];

  return (
    <tr className={styles["item"]}>
      <td
        className={clsx(
          styles["item-cell"],
          isDesktop ? styles["hover"] : styles["active"]
        )}
        onClick={updatePublicDisplay}
        title={
          data.publicDisplay ? "visible par tous" : "visibilité restreinte"
        }
      >
        <CenterCell>
          {data.publicDisplay ? (
            <VisibilityOutlined className={clsx(styles["visibility-icon"])} />
          ) : (
            <VisibilityOffOutlined
              className={clsx(
                styles["visibility-icon"],
                styles["visibility-off"]
              )}
            />
          )}
        </CenterCell>
      </td>
      <td className={styles["item-cell"]}>{data.name}</td>
      <td className={styles["item-cell"]}>
        <CenterCell className={styles["product-group-cell"]}>
          <ProductGroupLogo
            className={styles["product-group-logo"]}
            productGroup={data.productGroup}
          />
          {data.operationGroup && translate(data.operationGroup)}
        </CenterCell>
      </td>
      <td className={styles["item-cell"]}>
        {data.operationType && (
          <Select
            optionsList={OPERTATION_TYPE}
            value={translate(data.operationType)}
            setValue={updateOperationType}
          />
        )}
      </td>
      <td className={styles["item-cell"]}>
        <CenterCell>
          <Price price={data.priceNoTax} />
        </CenterCell>
      </td>
      <td className={styles["item-cell"]}>
        <CenterCell>
          <Price price={data.price} />
        </CenterCell>
      </td>
      <td className={styles["item-cell"]}>
        <CenterCell>
          <Price price={data.priceProBaseNoTax} />
        </CenterCell>
      </td>
      <td className={styles["item-cell"]}>
        <CenterCell>
          <Price price={data.priceProBase} />
        </CenterCell>
      </td>
      <td className={styles["item-cell"]}>
        <CenterCell>
          <Price price={data.priceProLuxeNoTax} />
        </CenterCell>
      </td>
      <td className={styles["item-cell"]}>
        <CenterCell>
          <Price price={data.priceProLuxe} />
        </CenterCell>
      </td>
      <td className={`${styles["item-cell"]} ${styles["tools"]}`}>
        <ProtectedComponent roles={["ROLE_ADMIN"]}>
          <CenterCell>
            <MoreTools options={MORE_TOOLS_OPTIONS} />
          </CenterCell>
        </ProtectedComponent>
      </td>
    </tr>
  );

  function handleDelete() {
    onDelete(data.slug);
  }

  async function updateOperationType(operationType: string) {
    try {
      const response = await updateOperation({ ...data, operationType });
      toast.success(
        "Type d'operation mis à jour avec succes.",
        TOAST_SUCCESS_OPTIONS
      );
      refetch();
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
  }

  async function updatePublicDisplay() {
    try {
      const response = await updateOperation({
        ...data,
        publicDisplay: !data.publicDisplay,
      });
      refetch();
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
  }
}
