import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import styles from "./IncomingShippingCheckIn.module.scss";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { generateDropOff } from "requests/dropOff";
import DataError from "components/errors/DataError/DataError";
import InStoreDropOff from "../../inStoreDropOff/InStoreDropOff";

export default function IncomingShippingCheckIn() {
  const { trackId } = useParams();
  const { data, isLoading } = useQuery(
    `drop-off-${trackId}`,
    () => generateDropOff(trackId!),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(trackId),
    }
  );

  if (isLoading) {
    return (
      <div className={styles["in-store-drop-off-edit"]}>
        <PictureLoadingSpinner />
      </div>
    );
  }

  if (!data) {
    return (
      <div className={styles["in-store-drop-off-edit"]}>
        <DataError error="drop-off" />
      </div>
    );
  }

  const { dropOff } = data;
  return <InStoreDropOff defaultDropOff={dropOff} />;
}
