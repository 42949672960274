import { IShipping } from "../../../../types/shipping.types";
import style from "./allDayShippingModal.module.scss";
import { Close } from "@mui/icons-material";
import { fetchShippingList } from "../../../../requests/shipping";
import { useQuery } from "react-query";
import { IOpenModal } from "../..";
import ExternalShippingItem from "./externalShippingItem";

export default function AllDayShippingModal({
  openModal,
}: {
  openModal: IOpenModal;
}) {
  const queryDate = openModal.date
    .toISOString()
    .substring(0, openModal.date.toISOString().indexOf("T"));
  const query = `period.start=${queryDate}T06:00:00.000Z&period.end=${queryDate}T18:00:00.000Z&shippingService=GP`;
  const { data, refetch } = useQuery(
    "shippingList",
    () => fetchShippingList(query),
    { refetchOnWindowFocus: true }
  );

  return (
    <div className={style["container"]}>
      <div className={style["modal"]}>
        <div className={style["close-button"]}>
          <Close />
        </div>
        {data && (
          <div className={style["allday-list"]}>
            <div className={style["title"]}>
              {new Date(openModal.date)
                .toLocaleDateString("fr-FR", {
                  day: "numeric",
                  weekday: "long",
                  month: "long",
                  year: "numeric",
                })
                .toUpperCase()}
            </div>
            {data &&
              data.shippingsList.map((value: IShipping, key: number) => {
                return (
                  <div key={key}>
                    <ExternalShippingItem value={value} refetch={refetch} />
                    {key < data.shippingsList.length - 1 && <hr />}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}
