import { useQuery } from "react-query";
import style from "./cartModal.module.scss";
import { fetchCart } from "../../../requests/carts";
import LoadingSpinner from "../../loadingSpinner/loadingSpinner";
import ContactInfoCard from "../../contactInfoCard/contactInfoCard";
import { Link } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import { IProduct } from "../../../types/logistic.types";
import { IOperation } from "../../../types/accounting.types";
import ProductInformations from "../../ProductInformations/ProductInformations";
import State from "../../State/State";
import DataError from "components/errors/DataError/DataError";

interface CartModalProps {
  id: string;
  onEdit: () => void;
}

export default function CartModal({ id, onEdit }: CartModalProps) {
  const { data, isFetching } = useQuery(["cart-modal", id], () =>
    fetchCart(id)
  );

  if (isFetching) {
    return (
      <div className={style["loading-container"]}>
        <LoadingSpinner color="gold" />
      </div>
    );
  }

  if (!data) {
    return <DataError error="cart" />;
  }

  return (
    <div className={style["cart-modal"]}>
      <div className={style["read-document"]}>
        <ContactInfoCard
          contact={data?.contact}
          className={style["cart-modal-contact-card"]}
          trackId={data?.trackId}
        />
        <div className={style["header"]}>
          <div className={style["header-left"]}>Panier</div>
          <div className={style["header-right"]}>
            <Link
              onClick={handleEdit}
              to={`/carts/edit/${data.id}`}
              className={style["tools-icon"]}
            >
              <Edit className={style["edit-icon"]} />
            </Link>
            <State state={data.state} className={style["state-container"]} />
          </div>
        </div>
        <div className={style["body"]}>
          <div className={style["product-information-container"]}>
            <ProductInformations
              productsList={data.productsList}
              operationsTotal={cartTotal()}
            />
          </div>
        </div>
      </div>
    </div>
  );

  function handleEdit() {
    onEdit();
    window.scrollTo(0, 0);
  }

  function cartTotal() {
    if (!data) {
      return;
    }
    return data.productsList.reduce((acc, product: IProduct) => {
      return (
        acc +
        product.operationsList.reduce(
          (acc, operation: IOperation) => acc + (operation.price || 0),
          0
        )
      );
    }, 0);
  }
}
