import { fetchShipping } from "../../../requests/shipping";
import { useQuery } from "react-query";
import Create from "../create/Create";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import style from "./edit.module.scss";
import DataError from "components/errors/DataError/DataError";

export default function Edit() {
  const { id } = useParams();
  const { data, isFetching, refetch } = useQuery(
    ["shippingDocument", id],
    () => fetchShipping({ id: id }),
    { refetchOnWindowFocus: false }
  );

  if (isFetching) {
    return <LoadingSpinner color="gold" />;
  }

  if (!data) {
    return <DataError error="shipping" />;
  }

  return (
    <>
      {!isFetching ? (
        <>{data && <Create refetch={refetch} shipping={data} />}</>
      ) : (
        <div className={style["loading-spinner-container"]}>
          <LoadingSpinner color="gold" />
        </div>
      )}
    </>
  );
}
