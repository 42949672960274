import styles from "./modalContainer.module.scss";
import { Close } from "@mui/icons-material";
import ModalPortal from "../ModalPortal";
import RemoveBodyScroll from "../RemoveBodyScroll";
import clsx from "clsx";
import { useState } from "react";
import ConfirmCloseModal from "./ConfirmCloseModal/ConfirmCloseModal";

interface ModalContainerProps {
  askCancelConfirmation?: boolean;
  children: React.ReactNode;
  isOpen: boolean;
  onCancel?: () => void;
  height?: "normal" | "fit";
  width?: "wide" | "normal" | "narrow" | "fit";
}

export function ModalContainer({
  askCancelConfirmation = false,
  children,
  isOpen,
  onCancel,
  height = "normal",
  width = "normal",
}: ModalContainerProps) {
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);

  return (
    <>
      <ModalPortal isOpen={isOpen}>
        <RemoveBodyScroll>
          <div className={styles["modal-container"]}>
            <div
              className={clsx(
                styles["close-button-container"],
                widthDispatch()
              )}
            >
              <div className={styles["close-button"]} onClick={handleOnCancel}>
                <Close />
              </div>
            </div>
            <div
              className={clsx(
                styles["modal-container-children"],
                widthDispatch(),
                heightDispatch()
              )}
            >
              {children}
            </div>
            <div
              className={styles["background"]}
              onClick={handleOnCancel}
            ></div>
          </div>
        </RemoveBodyScroll>
      </ModalPortal>
      <ModalPortal isOpen={openConfirmationModal}>
        <ConfirmCloseModal
          onConfirmClose={handleQuitModal}
          onCancel={handleCloseConfirmModal}
        />
      </ModalPortal>
    </>
  );

  function handleCloseConfirmModal() {
    setOpenConfirmationModal(false);
  }

  function handleQuitModal() {
    handleCloseConfirmModal();
    onCancel && onCancel();
  }

  function handleOnCancel() {
    if (askCancelConfirmation) {
      setOpenConfirmationModal(true);
    } else {
      onCancel && onCancel();
    }
  }

  function widthDispatch() {
    switch (width) {
      case "wide":
        return styles["wide-width"];
      case "normal":
        return styles["normal-width"];
      case "narrow":
        return styles["narrow-width"];
      case "fit":
        return styles["fit-width"];
      default:
        return styles["normal-width"];
    }
  }

  function heightDispatch() {
    switch (height) {
      case "fit":
        return styles["fit-height"];
      case "normal":
        return styles["normal-height"];
      default:
        return styles["normal-height"];
    }
  }
}
