import { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getShop } from "../../../requests/shops";
import SelectState from "../../../pages/shipping/create/SelectState/SelectState";
import { IContact } from "../../../types/logistic.types";
import {
  IProCart,
  IContact as IProContact,
  IProduct,
} from "../../../types/proCart.types";
import { CART_STATE } from "../../../utils/booking.init";
import { TOAST_ERROR_OPTIONS } from "../../../utils/toast.options";
import Button from "../../Button/Button";
import ContactForm from "../../forms/ContactForm/ContactForm";
import SectionFormTemplate from "../../forms/sectionFormTemplate/SectionFormTemplate";
import AddProductSection from "../addNewCartProModal/addProductSection/AddProductSection";
import style from "./proShippingModal.module.scss";

interface IProductShipping extends IProduct {
  canceled: boolean;
}

export interface IProCartShipping extends IProCart {
  productsList: IProductShipping[];
}

interface ProShippingModalProps {
  cart: IProCartShipping;
  setCart: (cart: IProCartShipping) => void;
}

export default function ProShippingModal({
  cart,
  setCart,
}: ProShippingModalProps) {
  const { data: shop } = useQuery(["get-shop", cart], () =>
    getShop(cart.shopId)
  );

  const [formValues, setFormValues] = useState<IProCart>({
    ...(cart as IProCart),
  });

  return (
    <div className={style["add-new-cart-pro-modal"]}>
      <div className={style["add-new-cart-pro-modal-top"]}>
        <div className={style["add-new-cart-pro-header"]}>
          <h1>Modifier Panier</h1>
          <SelectState
            label="Status de la demande"
            state={formValues.state}
            stateOption={CART_STATE}
            setState={(state) => {
              setFormValues({ ...formValues, state });
            }}
          />
        </div>
        <div>
          <SectionFormTemplate title={"Informations contact"} collapsedDefault>
            <ContactForm
              autoComplete={false}
              contact={formValues.contact}
              setContact={handleContactChange}
            />
          </SectionFormTemplate>
          <SectionFormTemplate title={"Référence interne"} collapsedDefault>
            <input
              value={formValues.customRef}
              onChange={({ target }) =>
                setFormValues((prev) => ({ ...prev, customRef: target.value }))
              }
            />
          </SectionFormTemplate>
          <SectionFormTemplate title={"Commentaire"} collapsedDefault>
            <textarea
              className={style["comment"]}
              value={formValues.comments}
              onChange={({ target }) =>
                setFormValues((prev) => ({ ...prev, comments: target.value }))
              }
            />
          </SectionFormTemplate>
          <hr className={style["separator"]} />
          <AddProductSection
            shopRef={shop?.workshopPrefixId || ""}
            shopId={cart.shopId}
            productsList={formValues.productsList}
            setProductsList={handleSetProductsList}
          />
        </div>
      </div>
      <div className={style["add-new-cart-pro-modal-footer"]}>
        <Button onClick={handleSubmit}>{"Valider"}</Button>
      </div>
    </div>
  );

  function handleContactChange(contact: IContact) {
    setFormValues((prev) => ({ ...prev, contact: contact as IProContact }));
  }

  function handleSetProductsList(productsList: IProduct[]) {
    setFormValues((prev) => ({ ...prev, productsList }));
  }

  function verifyForm() {
    return (
      formValues.productsList &&
      formValues.productsList.length > 0 &&
      formValues.productsList.every(
        (p) => p.workshopId && p.operationsList && p.operationsList.length > 0
      )
    );
  }

  async function handleSubmit() {
    if (!verifyForm()) {
      return toast.error(
        "Veuillez remplir les champs obligatoires et renseigner au moins 1 article et 1 opération.",
        TOAST_ERROR_OPTIONS
      );
    }
    try {
      setCart({ ...formValues } as IProCartShipping);
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
  }
}
