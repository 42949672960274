import { Delete, Edit, Key } from "@mui/icons-material";
import { ILicense } from "../../../types/license.types";
import styles from "./item.module.scss";
import { useMutation } from "react-query";
import { generateLicenseKey } from "../../../requests/licenses";
import LicenseKeysModal from "../../../components/modals/licenseKeysModal/licenseKeysModal";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../../../utils/toast.options";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import clsx from "clsx";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import CenterCell from "../../../components/CenterCell/CenterCell";
import Price from "../../../components/Price/Price";
import { useState } from "react";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";

interface GenerateLicenseKeyResponse {
  redirections: { email: string; redirectionEndpoint: string }[];
}

interface ItemProps {
  data: ILicense;
  onDelete: (id: string) => void;
}

export default function Item({ data, onDelete }: ItemProps) {
  const [openModal, setOpenModal] = useState<GenerateLicenseKeyResponse | null>(
    null
  );
  const generateKeyMutation = useMutation(generateLicenseKey, {
    onSuccess: (data) => {
      if (data?.redirections) {
        setOpenModal(data);
      }
    },
    onError: (error) => {
      toast.error("Une erreur est survenue", TOAST_ERROR_OPTIONS);
    },
  });

  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Edit />,
      name: "Ouvrir",
      to: `./edit/${data.id}`,
    },
    {
      icon: <Key />,
      name: "Clés",
      onClick: () => handleGenerateKey(),
    },
    {
      icon: <Delete />,
      name: "Supprimer",
      askConfirmation: true,
      onClick: () => handleDelete(),
    },
  ];

  return (
    <>
      <tr className={styles["item"]}>
        <td className={styles["item-cell"]}>
          {data.contact?.organization} - {data.contact?.displayname}
        </td>
        <td className={styles["item-cell"]}>{(data.shopsList || []).length}</td>
        <td className={styles["item-cell"]}>
          <Price
            price={
              (data.monthlyPrice || 0) +
              (data.shopsList || []).reduce(
                (prev, cur) => prev + (cur.monthlyPrice || 0),
                0
              )
            }
          />
        </td>
        <td className={clsx(styles["item-cell"], styles["tools"])}>
          <ProtectedComponent roles={["ROLE_ADMIN"]}>
            <CenterCell>
              <MoreTools options={MORE_TOOLS_OPTIONS} />
            </CenterCell>
          </ProtectedComponent>
        </td>
      </tr>
      <ModalContainer isOpen={!!openModal} onCancel={handleCloseModal}>
        <LicenseKeysModal redirections={openModal?.redirections!} />
      </ModalContainer>
    </>
  );

  function handleCloseModal() {
    setOpenModal(null);
  }

  function handleDelete() {
    data.id && onDelete(data.id);
  }

  function handleGenerateKey() {
    if (data?.id) {
      generateKeyMutation.mutate({ id: data.id });
    }
  }
}
