import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getShipping,
  updateShippingStateMutation,
} from "../../../../../../requests/shipping";
import PictureLoadingSpinner from "../../../../../../components/loadingSpinner/pictureloadingSpinner";
import DataError from "../../../../../../components/errors/DataError/DataError";
import styles from "./shippingInformation.module.scss";
import ShippingServiceLogo from "../../../../../../components/ShippingServiceLogo/ShippingServiceLogo";
import Button from "../../../../../../components/Button/Button";
import Address from "./Address/Address";
import ShippingDate from "./ShippingDate/ShippingDate";
import State from "../../../../../../components/State/State";
import clsx from "clsx";
import { ITracker } from "../../../../../../types/tracker.types";
import { Check, Edit } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { STD_ERROR_MESSAGE } from "../../../../../../utils/constants";
import DownloadLabelButton from "./DownloadLabel/DownloadLabel";

interface ShippingInformationProps {
  shippingId: string;
  direction: string;
  tracker: ITracker;
}

export default function ShippingInformation({
  shippingId,
  direction,
  tracker,
}: ShippingInformationProps) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    data,
    isLoading,
    refetch: refetchShipping,
  } = useQuery(["shipping", shippingId], () => getShipping(shippingId), {
    enabled: Boolean(shippingId),
  });
  const updateStateMutation = useMutation(updateShippingStateMutation, {
    onSuccess: () => {
      queryClient.refetchQueries(["tracker-overview", tracker.id]);
    },
    onError: () => {
      toast.error(STD_ERROR_MESSAGE);
    },
  });

  if (!shippingId) {
    return (
      <div className={styles["shipping-information"]}>
        <p></p>
        <Button
          onClick={() =>
            navigate(
              `/shipping-calendar/generate/${tracker.id}/${
                direction === "collecte" ? "incoming" : "outgoing"
              }`
            )
          }
        >{`Programmer une ${direction}`}</Button>
      </div>
    );
  }

  if (isLoading) {
    return <PictureLoadingSpinner />;
  }

  if (!data) {
    return <DataError error={"shipping"} />;
  }

  return (
    <div
      className={clsx(
        styles["shipping-information"],
        data.state === "DELIVERED" &&
          direction === "collecte" &&
          styles["delivered"]
      )}
    >
      <div className={styles["shipping-information-top"]}>
        <ShippingDate period={data.period} />
        <Link
          className={styles["edit-button"]}
          to={`/shipping-calendar/edit/${shippingId}`}
        >
          <Edit className={styles["icon"]} />
        </Link>
        <div className={styles["shipping-information-right"]}>
          {data.state !== "DELIVERED" && (
            <Button
              className={styles["finish-shipping"]}
              onClick={handleSetShippingAsFinished}
              isLoading={updateStateMutation.isLoading}
            >
              Livré
              <Check className={styles["icon"]} />
            </Button>
          )}
          <State state={data.state} className={styles["state"]} />
        </div>
      </div>
      <Address
        shippingPoint={
          data.direction === "INCOMING" ? data.sender : data.recipient
        }
      />
      <div className={styles["shipping-service"]}>
        <ShippingServiceLogo
          externalId={data?.externalShipping?.externalId}
          shippingService={data?.shippingMethod?.shippingService}
        />
        {data?.shippingMethod?.shippingService.includes("CHRONO") && (
          <DownloadLabelButton shipping={data} refetch={refetchShipping} />
        )}
      </div>
    </div>
  );

  function handleSetShippingAsFinished() {
    updateStateMutation.mutate({ id: data?.id!, state: "DELIVERED" });
  }
}
