import React, { ReactElement, useState } from "react";
import Paper from "@mui/material/Paper";
import {
  AppointmentModel,
  CurrentTimeIndicator,
  GroupingState,
  ValidResourceInstance,
  ViewState,
} from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  Appointments,
  AllDayPanel,
} from "@devexpress/dx-react-scheduler-material-ui";
import { AppointmentTooltip } from "@devexpress/dx-react-scheduler-material-ui";
import style from "./calendar.module.scss";
import CalendarView from "./calendarView";
import { ISchedulerData } from "../../../utils/formatters/schedulerData.formatter";
import ShippingModalSelect from "./shippingModal/shippingModalSelect";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";

export default function Calendar({
  schedulerData,
  date,
  view,
  setView,
  setDate,
}: {
  schedulerData: ISchedulerData[];
  date: Date;
  view: string;
  setView: (view: string) => void;
  setDate: (date: Date) => void;
}) {
  return (
    <Paper>
      <Scheduler data={schedulerData} locale={"fr-FR"}>
        <ViewState currentDate={date} />
        <GroupingState
          grouping={[{ resourceName: "type" }]}
          groupByDate={(viewName: string) => true}
        />
        <CalendarView
          view={view}
          setView={setView}
          setDate={setDate}
          date={date}
        />
        <Appointments
          appointmentComponent={(props) =>
            ShippingAppointment({ ...props, setView })
          }
        />
        <AllDayPanel
          messages={{
            allDay: "Chronopost",
          }}
        />
        {/* <AllDayPanel
          messages={{
            allDay: "Journée",
          }}
        /> */}
        <AppointmentTooltip showOpenButton showCloseButton />
        <CurrentTimeIndicator
          shadePreviousCells
          shadePreviousAppointments
          updateInterval={1000}
          indicatorComponent={() => <></>}
        />
      </Scheduler>
    </Paper>
  );
}

interface ShippingAppointmentProps {
  children: React.ReactNode;
  data: AppointmentModel;
  draggable: boolean;
  onClick?: (e: any) => void;
  onDoubleClick?: (e: any) => void;
  isShaded?: boolean;
  resources: Array<ValidResourceInstance>;
  setView: (view: string) => void;
}

function ShippingAppointment({
  setView,
  children,
  data,
  resources,
}: ShippingAppointmentProps): ReactElement<Appointments.AppointmentProps> {
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <Appointments.Appointment
        draggable
        data={data}
        resources={resources}
        className={appointmentBackgroundColor(data.type, data.service || "")}
        onClick={handleOpenModal}
        onDoubleClick={() => setView("day")}
      >
        {children}
      </Appointments.Appointment>
      <ModalContainer isOpen={openModal} onCancel={handleCloseModal}>
        <ShippingModalSelect
          openModal={{
            isExternal: data?.allDay || false,
            isAllDay: data?.isAllDay || false,
            date: new Date(data.startDate),
            id: data.id as string,
          }}
        />
      </ModalContainer>
    </>
  );

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleOpenModal() {
    setOpenModal(true);
  }

  function appointmentBackgroundColor(type: string, service: string) {
    switch (type) {
      case "INCOMING-VALID":
        if (service === "DROP-OR-COLLECT") {
          return `${style["incoming-shop"]} ${style["shipping-appointment"]}`;
        }
        return `${style["incoming"]} ${style["shipping-appointment"]}`;
      case "OUTGOING-VALID":
        if (service.includes("DROP")) {
          return `${style["outgoing-shop"]} ${style["shipping-appointment"]}`;
        }
        return `${style["outgoing"]} ${style["shipping-appointment"]}`;
      case "INCOMING-DRAFT":
        return `${style["incoming-draft"]} ${style["shipping-appointment"]}`;
      case "OUTGOING-DRAFT":
        return `${style["outgoing-draft"]} ${style["shipping-appointment"]}`;
      case "CHRONOPOST":
        return `${style["chronopost"]} ${style["shipping-appointment"]}`;
      case "month-shipping":
        return `${style["month-shipping"]} ${style["shipping-appointment"]}`;
      case "month-chronopost":
        return `${style["month-chronopost"]} ${style["shipping-appointment"]}`;
      case "month-canceled":
        return `${style["month-canceled"]} ${style["shipping-appointment"]}`;

      default:
        return `${style["all-day"]} ${style["shipping-appointment"]}`;
    }
  }
}
