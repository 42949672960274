import style from "./externalShippingModal.module.scss";
import { useState } from "react";
import translate from "../../../../utils/translation";
import { IProduct } from "../../../../types/logistic.types";
import getStatusColor from "../../../../utils/translation/statusColor";
import { Tag } from "react-bootstrap-icons";
import LogisticModal from "../../../../components/modals/logisticModal/logisticModal";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";

interface ShippingProductStateProps {
  logisticId: string;
  product: IProduct;
  index: number;
}

export default function ShippingProductState({
  logisticId,
  product,
  index,
}: ShippingProductStateProps) {
  const [showProductState, setShowProductState] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  return (
    <>
      <div
        className={style["modal-product-state-container"]}
        key={`${index}-${product.workshopId}`}
        onClick={toogleLogisticModal}
      >
        {product.status && ( //showProductState &&
          <div
            className={style["modal-product-state"]}
            style={{ backgroundColor: `${getStatusColor(product.status)}` }}
          >
            {translate(product.status)}
          </div>
        )}
        <div
          className={style["workshop-id"]}
          onClick={() => setShowProductState(!showProductState)}
        >
          {product.workshopId}
          <Tag />
        </div>
      </div>
      <ModalContainer isOpen={modalIsOpen} onCancel={toogleLogisticModal}>
        <LogisticModal id={logisticId} requiredActions={false} />
      </ModalContainer>
    </>
  );

  async function toogleLogisticModal() {
    setModalIsOpen(!modalIsOpen);
  }
}
