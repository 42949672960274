import { useEffect, useRef, useState } from "react";
import style from "./create.module.scss";
import { ICrossSell } from "../../../types/accounting.types";
import { PRODUCT_GROUP } from "../../../utils/logistic.init";
import translate from "../../../utils/translation";
import "react-image-crop/src/ReactCrop.scss";
import slugify from "slugify";
import { streamFile } from "../../../requests/file";
import {
  addCrossSellItem,
  checkIfSlugExist,
  updateCrossSellItem,
} from "../../../requests/crossSelling";
import { IValidationError } from "../../../types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { TOAST_OPTIONS } from "../../../utils/toast.options";
import { ImageCrop } from "../../../components/imageCrop/imageCrop";
import {
  CameraAltRounded,
  Close,
  Delete,
  Crop as CropIcon,
} from "@mui/icons-material";
import { Crop } from "react-image-crop";
import { handleCropImage } from "../../../utils/cropImage.function";
import Input from "../../../components/inputs/Input/Input";
import Select from "../../../components/Select/Select";
import Button from "../../../components/Button/Button";
import SectionFormTemplate from "../../../components/forms/sectionFormTemplate/SectionFormTemplate";
import CheckboxItem from "../../../components/checkboxItem/CheckboxItem";
import ColorPicker from "../../../components/ColorPicker/ColorPicker";

const DEFAULT_QUANTITY = 50;

interface CreateProps {
  data?: ICrossSell;
}

export default function Create({ data }: CreateProps) {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [startCrop, setStartCrop] = useState<boolean>(false);
  const [rawImage, setRawImage] = useState<HTMLImageElement | null>(null);
  const [crop, setCrop] = useState<Crop>();

  const [image, setImage] = useState<File | null | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<ICrossSell>(
    data || {
      quantity: DEFAULT_QUANTITY,
    }
  );
  const [validationError, setValidationError] = useState<IValidationError[]>(
    []
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (formValues.image && !data) {
      submitForm();
    }
    if (data && formValues.image && formValues.image !== data.image) {
      submitForm();
    }
  }, [formValues.image]);

  useEffect(() => {
    if (data) {
      return;
    }
    if (formValues.description || formValues.color) {
      const newSlug = slugify(
        [formValues.description, formValues.color].join(" "),
        "_"
      ).toLocaleLowerCase();
      setFormValues((prev) => ({ ...prev, slug: newSlug }));
    } else {
      setFormValues((prev) => ({ ...prev, slug: "" }));
    }
  }, [formValues.description, formValues.color]);

  //Debounce function to live check if slug exist
  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(debouncedFunction, 600);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [formValues.slug]);

  useEffect(() => {
    async function getImage() {
      if (formValues.image) {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/file/${formValues.image}`,
          {
            credentials: "include",
          }
        );
        const blob = await response.blob();
        const file = new File([blob], "image");
        setImage(file);
      }
    }
    getImage();
  }, []);

  return (
    <div className={style["cross-selling-create"]}>
      <div className={style["title"]}>
        Ajouter un nouvel article Cross-selling
      </div>
      <div className={style["cross-selling-form"]}>
        <div className={style["cross-selling-form-top"]}>
          <div className={style["cross-selling-form-left"]}>
            <SectionFormTemplate
              className={style["section-from-template"]}
              title="Description"
            >
              <Input
                type="text"
                name="description"
                value={formValues.description ?? ""}
                onChange={handleOnChange}
                className={isRequired("description")}
                onFocus={handleStartCorrection}
              />
            </SectionFormTemplate>
            <SectionFormTemplate
              className={style["section-from-template"]}
              title="Groupe d'article"
            >
              <div className={style["product-group-list"]}>
                {PRODUCT_GROUP.map((value: string, index: number) => {
                  return (
                    <CheckboxItem
                      className={style["product-group-item"]}
                      checked={(formValues.productGroup ?? []).includes(value)}
                      onChecked={() => handleProductGroupSelected(value)}
                      key={index}
                      title={translate(value)}
                    />
                  );
                })}
              </div>
            </SectionFormTemplate>
            <SectionFormTemplate
              className={style["section-from-template"]}
              title="Catégorie"
            >
              <Select
                className={style["select-category"]}
                optionsList={["Cirage", "Crème surfine", "Brosses", "Divers"]}
                value={formValues.category || ""}
                setValue={(value) =>
                  setFormValues((prev) => ({ ...prev, category: value }))
                }
                placeholder="Sélectionner une catégorie..."
              />
            </SectionFormTemplate>
            <SectionFormTemplate
              className={style["section-from-template"]}
              title="Prix (TTC)"
            >
              <Input
                type="number"
                inputMode={"numeric"}
                value={formValues.price ? formValues.price / 100 : undefined}
                onChange={({ target }) =>
                  setFormValues((prev) => ({
                    ...prev,
                    price: parseFloat(target.value) * 100,
                  }))
                }
                name="price"
                className={isRequired("price")}
                onFocus={handleStartCorrection}
              />
            </SectionFormTemplate>
            <SectionFormTemplate
              className={style["section-from-template"]}
              title="Quantité"
            >
              <Input
                type="number"
                inputMode={"numeric"}
                value={formValues.quantity}
                onChange={handleOnChange}
                name="quantity"
                className={isRequired("quantity")}
                onFocus={handleStartCorrection}
              />
            </SectionFormTemplate>
            <SectionFormTemplate
              className={style["section-from-template"]}
              title="Couleur"
            >
              <ColorPicker
                color={formValues.color || ""}
                onColorChange={(value) =>
                  setFormValues((prev) => ({ ...prev, color: value }))
                }
              />
            </SectionFormTemplate>
            <SectionFormTemplate
              className={style["section-from-template"]}
              title={`Slug ${
                showAlreadyExistsMessage() ? "(ce slug existe déjà)" : ""
              }`}
            >
              <Input
                type="text"
                name="slug"
                disabled={Boolean(data)}
                value={formValues.slug ?? ""}
                onChange={handleOnChange}
                className={isRequired("slug")}
                onFocus={handleStartCorrection}
              />
            </SectionFormTemplate>
          </div>
          <div className={style["cross-selling-form-right"]}>
            {image ? (
              <ImageCrop
                image={image}
                setRawImage={setRawImage}
                crop={crop}
                setCrop={setCrop}
                startCrop={startCrop}
              />
            ) : (
              <button
                type="button"
                className={style["add-image-button"]}
                onClick={handleAddClick}
              >
                Ajouter une image
                <CameraAltRounded className={style["add-image-icon"]} />
              </button>
            )}
            {image && (
              <div className={style["action-buttons"]}>
                <button
                  type="button"
                  className={style["delete-image-button"]}
                  onClick={handleStartCrop}
                >
                  {startCrop ? <Close /> : <CropIcon />}
                </button>
                <button
                  type="button"
                  className={style["delete-image-button"]}
                  onClick={handleDeleteImage}
                >
                  <Delete />
                </button>
                {startCrop && (
                  <button
                    type="button"
                    className={style["delete-image-button"]}
                    onClick={() =>
                      handleCropImage(
                        crop,
                        rawImage,
                        resetImageUri,
                        setImage,
                        startCrop,
                        setStartCrop
                      )
                    }
                  >
                    Crop
                    <CropIcon />
                  </button>
                )}
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              className={style["file-input"]}
              ref={hiddenFileInput}
              onChange={handleImageChange}
            />
          </div>
        </div>
        <div className={style["button-container"]}>
          <Button type="submit" onClick={handleSubmit} isLoading={isLoading}>
            Enregister
          </Button>
        </div>
      </div>
    </div>
  );

  function handleDeleteImage() {
    setImage(null);
    setRawImage(null);
    resetImageUri();
  }

  function handleStartCrop() {
    setStartCrop(!startCrop);
  }

  function handleAddClick() {
    hiddenFileInput?.current?.click();
  }

  function handleImageChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      setImage(e.target.files[0]);
      resetImageUri();
    }
  }

  async function handleSubmit() {
    // e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    // e.preventDefault();
    if (!formValues.slug) {
      toast.error("Veuillez ajouter un Slug pour cet article.", TOAST_OPTIONS);
      return addRequiredValidationError("slug");
    }
    setIsLoading(true);
    try {
      if (!(data && formValues.slug === data.slug)) {
        const slugExist = await checkIfSlugExist(formValues.slug);
        if (slugExist) {
          toast.error(
            "Le slug que vous avez rentrez existe déjà. Veuillez en choisir un autre.",
            TOAST_OPTIONS
          );
          return;
        }
      }
      if (!formValues.image) {
        await submitFile();
      } else {
        await submitForm();
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function submitForm() {
    const response = data
      ? await updateCrossSellItem(formValues)
      : await addCrossSellItem(formValues);
    if ((response as IValidationError[]).length > 0) {
      setValidationError(response as IValidationError[]);
      toast.error("Veuillez remplir les champs obligatoire.", TOAST_OPTIONS);
      return;
    }
    toast.success("Article ajouter avec succès.", TOAST_OPTIONS);
    navigate("../");
  }

  async function submitFile() {
    if (!image || !formValues.slug) {
      toast.error("Veuillez ajouter une image.", TOAST_OPTIONS);
      return;
    }
    try {
      const response = await streamFile("_" + formValues.slug + ".", image);
      setFormValues((prev) => ({ ...prev, image: response.message }));
      toast.success(
        `Image ajoutée avec succès ${response.message}`,
        TOAST_OPTIONS
      );
    } catch (error) {
      console.error(error);
    }
  }

  function handleOnChange({
    target,
  }: {
    target: (EventTarget & HTMLInputElement) | HTMLSelectElement;
  }) {
    let value: string | number = target.value;
    if (target.type === "number") {
      if (!isNaN(parseInt(target.value))) value = parseInt(target.value);
    }
    setFormValues((prev) => ({ ...prev, [target.name]: value }));
  }

  function resetImageUri() {
    setFormValues((prev) => ({ ...prev, image: "" }));
  }

  function handleColorChange({
    name,
    value,
  }: {
    name: string;
    value: string | number;
  }) {
    setFormValues((prev) => ({ ...prev, color: value as string }));
  }

  function addRequiredValidationError(field: string) {
    setValidationError((prev) => [
      ...prev,
      { field: field, errorType: "required" },
    ]);
  }

  function addSlugExistValidationError(field: string) {
    setValidationError((prev) => [
      ...prev,
      { field: field, errorType: "exist" },
    ]);
  }

  function removeSlugExistValidationError(field: string) {
    const newValidation = validationError.filter(
      (value: IValidationError) =>
        value.field !== field && value.errorType !== "exist"
    );
    setValidationError([...newValidation]);
  }

  function handleProductGroupSelected(value: string) {
    const newProductGroup = [...(formValues.productGroup || [])];
    !newProductGroup.includes(value)
      ? newProductGroup.push(value)
      : newProductGroup.splice(
          newProductGroup.findIndex((pg: string) => pg === value),
          1
        );
    setFormValues((prev) => ({ ...prev, productGroup: newProductGroup }));
  }

  async function debouncedFunction() {
    if (data && formValues.slug === data.slug) {
      return;
    }
    try {
      if (formValues.slug) {
        const slugExist = await checkIfSlugExist(formValues.slug);
        slugExist
          ? addSlugExistValidationError("slug")
          : removeSlugExistValidationError("slug");
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handleStartCorrection({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    const newValidation = validationError.filter(
      (value: IValidationError) => value.field !== target.name
    );
    setValidationError([...newValidation]);
  }

  function showAlreadyExistsMessage() {
    return validationError.find(
      (value: IValidationError) =>
        value.field === "slug" && value.errorType === "exist"
    );
  }
  function isRequired(name: string): string {
    if (
      validationError.find((value: IValidationError) => name === value.field)
    ) {
      return style["validation-error"];
    }
    return "";
  }
}
