import { ITracker } from "../../../../../../types/tracker.types";
import styles from "./overviewAction.module.scss";
import { TRACKING_STATE } from "../../../../../../utils/tracking.init";
import StateSelect from "../../../../../../components/StateSelect/StateSelect";
import OverviewActionDispatch from "./OverviewActionDispatch/OverviewActionDispatch";
import MoreAction from "../../../../../../components/MoreAction/MoreAction";
import { IMoreToolsOptions } from "../../../../../../components/MoreTools/MoreTools";
import { OverviewTabs } from "../../../../../../types/overview.types";
import { updateTrackerStateMutation } from "requests/tracker";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "utils/toast.options";

interface OverviewActionProps {
  tracker: ITracker;
  onChangeTab: (tab: OverviewTabs) => void;
}

export default function OverviewAction({
  tracker,
  onChangeTab,
}: OverviewActionProps) {
  const queryClient = useQueryClient();
  const updateTrackerState = useMutation(updateTrackerStateMutation, {
    onSuccess: () => {
      queryClient.refetchQueries(["tracker-overview", tracker.id]);
      toast.success(
        "L'état à était mis à jour avec succès.",
        TOAST_SUCCESS_OPTIONS
      );
    },
    onError: () => {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    },
  });

  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      name: "Annuler la commande",
      onClick: handleOnCancelOrder,
    },
  ];

  function handleOnCancelOrder() {}

  return (
    <div className={styles["overview-action"]}>
      <div className={styles["overview-action-container"]}>
        <StateSelect
          state={tracker.state}
          onStateChange={handleChangeTrackerState}
          className={styles["select-state"]}
          list={TRACKING_STATE}
          isTracker
        />
        <OverviewActionDispatch tracker={tracker} onChangeTab={onChangeTab} />
        <MoreAction options={MORE_TOOLS_OPTIONS} />
      </div>
    </div>
  );

  function handleChangeTrackerState(state: string) {
    if (!tracker.id) {
      return;
    }
    updateTrackerState.mutate({ state, trackerId: tracker.id });
  }
}
