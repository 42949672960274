import styles from "./unfinishedListWidget.module.scss";
import useSaveQueryStorage from "../../../../hooks/useSaveQueryStorage";
import { queryObject } from "../../../../types/query.types";
import PagingComponent from "../../../../components/pagingComponent/pagingComponent";
import { useQuery } from "react-query";
import { fetchUnfinishedTrackersList } from "../../../../requests/tracker";
import UnfinishedList from "./UnfinishedList/UnfinishedList";
import DashboardWidget from "../DashboardWidget";
import DashboardWidgetHeader from "../DashboardWidgetHeader/DashboardWidgetHeader";
import LoadingWidget from "../LoadingWidget/LoadingWidget";
import ErrorWidget from "../ErrorWidget/ErrorWidget";
import ShippingServiceLogo from "../../../../components/ShippingServiceLogo/ShippingServiceLogo";
import { useState } from "react";
import { truncate } from "fs";

export const INITIAL_QUERY: queryObject = {
  limit: 5,
  offset: 0,
};

export default function UnfinishedListWidget() {
  const [checkedFilters, setCheckedFilters] = useState({
    INTERNAL: true,
    "CHRONOPOST-2SHOP": true,
    "CHRONOPOST-EUR": true,
    "DROP-OR-COLLECT": true,
  });

  const [query, setQuery] = useSaveQueryStorage(
    INITIAL_QUERY,
    "unfinished-list"
  );

  const { data, isLoading } = useQuery(
    ["unfinishedTrackersList-widget", query],
    () => fetchUnfinishedTrackersList("?" + formatedQuery()),
    {
      keepPreviousData: true,
      select: (data) => {
        let list: any[] = (data.trackersList as any[]).filter(
          (item) =>
            item?.outgoingShipping?.shippingService &&
            checkedFilters[
              item.outgoingShipping
                .shippingService as keyof typeof checkedFilters
            ]
        );
        return {
          totalCount: data.totalCount,
          trackersList: list,
        };
      },
    }
  );

  if (isLoading) {
    return <LoadingWidget />;
  }

  if (!data) {
    return <ErrorWidget />;
  }

  return (
    <DashboardWidget>
      <div className={styles["unfinished-list-widget"]}>
        <DashboardWidgetHeader
          title={"Livraison moins de 48h"}
          totalCount={data.totalCount}
        />
        <div className={styles["filters"]}>
          <div className={styles["checkbox-input-container"]}>
            <input
              type="checkbox"
              checked={checkedFilters.INTERNAL}
              onChange={() =>
                setCheckedFilters((prev) => ({
                  ...prev,
                  INTERNAL: !prev.INTERNAL,
                }))
              }
            />
            <ShippingServiceLogo shippingService="INTERNAL" />
          </div>
          <div className={styles["checkbox-input-container"]}>
            <input
              type="checkbox"
              checked={checkedFilters["CHRONOPOST-2SHOP"]}
              onChange={() =>
                setCheckedFilters((prev) => ({
                  ...prev,
                  "CHRONOPOST-2SHOP": !prev["CHRONOPOST-2SHOP"],
                }))
              }
            />
            <ShippingServiceLogo shippingService="CHRONOPOST-2-SHOP" />
          </div>
          <div className={styles["checkbox-input-container"]}>
            <input
              type="checkbox"
              checked={checkedFilters["DROP-OR-COLLECT"]}
              onChange={() =>
                setCheckedFilters((prev) => ({
                  ...prev,
                  "DROP-OR-COLLECT": !prev["DROP-OR-COLLECT"],
                }))
              }
            />
            <ShippingServiceLogo shippingService="DROP-OR-COLLECT" />
          </div>
        </div>
        <div className={styles["body"]}>
          <UnfinishedList list={data.trackersList} />
        </div>
        <div className={styles["footer"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data.totalCount}
          />
        </div>
      </div>
    </DashboardWidget>
  );

  function formatedQuery() {
    return (
      new URLSearchParams({
        limit: query.limit.toString(),
        offset: (query.limit * query.offset).toString(),
      }).toString() + "&sort_field=updatedAt&sort_direction=desc"
    );
  }
}
