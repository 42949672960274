import { useParams } from "react-router-dom";
import Create from "../create/create";
import { useQuery } from "react-query";
import { fetchCustomer } from "../../../requests/customer";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import styles from "./edit.module.scss";
import DataError from "components/errors/DataError/DataError";

export default function Edit() {
  const { id } = useParams();
  const { data, isFetching } = useQuery(
    ["customer", id],
    () => fetchCustomer(id),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isFetching) {
    return (
      <div className={styles["loading-spinner-container"]}>
        <LoadingSpinner color="gold" />
      </div>
    );
  }

  if (!data) {
    return <DataError error="Data is missing" />;
  }

  return <Create data={data} />;
}
