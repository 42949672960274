import { useState } from "react";
import ProductCard from "../../productCard/ProductCard";
import style from "./operationTypeCard.module.scss";
import { useQuery } from "react-query";
import { queryObject } from "../../../../../types/query.types";
import { getProductList } from "../../../../../requests/product";
import PagingComponent from "../../../../../components/pagingComponent/pagingComponent";
import PictureLoadingSpinner from "../../../../../components/loadingSpinner/pictureloadingSpinner";
import { ChevronRight, ExpandMore } from "@mui/icons-material";

const INITIAL_QUERY: queryObject = {
  limit: 10,
  offset: 0,
  sort: "",
  operationGroup: "",
  operationType: "LEATHER_AUTRE",
  field: "",
  value: "",
};

interface OperationTypeCardProps {
  operationType: string;
  title: string;
  workshopId?: string;
}

export default function OperationTypeCard({
  operationType,
  title,
  workshopId,
}: OperationTypeCardProps) {
  const [query, setQuery] = useState<queryObject>(INITIAL_QUERY);
  const [showOperationList, setShowOperationList] = useState<boolean>(false);
  const { data } = useQuery(
    [`cobbler-${operationType}`, query, workshopId],
    () => getProductList(formatedQuery(operationType)),
    { keepPreviousData: true }
  );

  return (
    <>
      <div
        className={style["operation-type-card"]}
        onClick={() => setShowOperationList(!showOperationList)}
      >
        <div className={style["operation-type-card-left"]}>
          <div className={style["title"]}>
            <div className={style["chevron"]}>
              {showOperationList ? <ExpandMore /> : <ChevronRight />}
            </div>
            <span>{title}</span>
          </div>
          {data && data.productsList[0]?.dueDate && (
            <span className={style["earliest-product-dueDate"]}>
              Date de livraison la plus proche{" "}
              {new Date(data.productsList[0].dueDate).toLocaleDateString(
                "fr-FR",
                {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                }
              )}
            </span>
          )}
        </div>
        <div className={style["operation-type-card-right"]}>
          {data ? (
            data.totalCount
          ) : (
            <PictureLoadingSpinner
              color="white"
              className={style["loading-spinner"]}
            />
          )}
        </div>
      </div>
      {showOperationList && (
        <>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
          <div className={style["product-list"]}>
            {data &&
              data.productsList.map((p, index: number) => {
                return (
                  <ProductCard
                    key={index}
                    product={p}
                    showOperationAcronym={true}
                  />
                );
              })}
          </div>
          <hr />
        </>
      )}
    </>
  );

  function formatedQuery(operationType?: string) {
    let formatedQuery: string = `?limit=${query.limit}&offset=${
      query.offset * query.limit
    }&productGroup=LEATHER&status=WORKSHOP_REPAIR;WORKSHOP_CARE;EXTERNAL_JOB&operationsList.canceled=false`; //

    if (workshopId) {
      formatedQuery += `&workshopId=${workshopId}`;
    }

    if (operationType) {
      formatedQuery += `&operationsList.operationType=${operationType}`;
    }

    return formatedQuery;
  }
}
