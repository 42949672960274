import { useMutation, useQuery } from "react-query";
import styles from "./edit.module.scss";
import { getShop, updateShopMutation } from "../../../requests/shops";
import { useParams } from "react-router-dom";
import PictureLoadingSpinner from "../../../components/loadingSpinner/pictureloadingSpinner";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import Input from "../../../components/inputs/Input/Input";
import { useState } from "react";
import Button from "../../../components/Button/Button";
import DataError from "components/errors/DataError/DataError";

export default function Edit() {
  const { id } = useParams();

  const { data, isFetching, refetch } = useQuery(
    ["shop", id],
    () => getShop(id),
    {
      refetchOnWindowFocus: false,
    }
  );
  const [cartId, setCartId] = useState<string>("");
  const updateMutation = useMutation(updateShopMutation, {
    onSuccess: () => {
      toast.success("Mise à jour avec succès.", TOAST_SUCCESS_OPTIONS);
      refetch();
    },
    onError: () => {
      toast.error("Une erreur est survenue", TOAST_ERROR_OPTIONS);
    },
  });

  if (isFetching) {
    return <PictureLoadingSpinner />;
  }

  if (!data) {
    return <DataError error="Data is missing" />;
  }

  return (
    <div className={styles["edit"]}>
      <p>{`Nombre total de commandes : ${data.cartsId?.length}`}</p>
      <div>
        <Input
          label="Carts ID"
          onChange={handleOnChange}
          value={cartId}
          type="text"
        />
        <Button className={styles["button"]} onClick={addCart}>
          ajouter
        </Button>
        <Button className={styles["button"]} onClick={removeCart}>
          enlever
        </Button>
      </div>
    </div>
  );

  function handleOnChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    setCartId(target.value);
  }

  function addCart() {
    if (!data?.cartsId) {
      return;
    }
    if (!data.cartsId.includes(cartId)) {
      updateMutation.mutate({
        data: { ...data, cartsId: [...data.cartsId, cartId] },
        id: data.id,
      });
    }
  }

  function removeCart() {
    if (!data) {
      return;
    }
    updateMutation.mutate({
      data: {
        ...data,
        cartsId: (data.cartsId || []).filter((c) => c !== cartId),
      },
      id: data.id,
    });
  }
}
