import Price from "components/Price/Price";
import styles from "./addCrossSellModal.module.scss";
import { useState } from "react";
import { useQuery } from "react-query";
import { getCrossSellsList } from "requests/crossSelling";
import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import Input from "components/inputs/Input/Input";
import DataError from "components/errors/DataError/DataError";
import clsx from "clsx";
import { isDesktop } from "react-device-detect";
import { DropOffForm } from "types/logistic.types";
import { ICrossSellProduct } from "types/accounting.types";
import ColorComponent from "components/colorComponent/colorComponent";
import Image from "components/image/Image";

interface AddCrossSellModalProps {
  dropOff: DropOffForm;
  onAddCrossSell: (crossSell?: ICrossSellProduct) => void;
  onCancel: () => void;
}

export default function AddCrossSellModal({
  dropOff,
  onAddCrossSell,
  onCancel,
}: AddCrossSellModalProps) {
  const [name, setName] = useState<string>("");
  const { data, isLoading } = useQuery(
    ["quick-access-cross-sell", name],
    () => getCrossSellsList(name ? `?description=${name}` : ""),
    { keepPreviousData: true }
  );

  if (isLoading) {
    return (
      <div className={styles["add-cross-sell-modal"]}>
        <PictureLoadingSpinner />
      </div>
    );
  }

  if (!data) {
    return (
      <div className={styles["add-cross-sell-modal"]}>
        <DataError error="cross-sell-list" />
      </div>
    );
  }

  const { crossSells } = data;

  return (
    <div className={styles["add-cross-sell-modal"]}>
      <Input
        placeholder="Autres articles"
        className={styles["cross-sell-input"]}
        value={name}
        onChange={({ target }) => setName(target.value)}
      />
      <ul className={styles["cross-sell-list"]}>
        {crossSells.map((cs, index) => (
          <li
            className={clsx(
              styles["cross-sell"],
              dropOff.crossSells.find(
                (dropOffCs) => cs.slug === dropOffCs.slug
              ) && styles["selected"]
            )}
            key={index}
            onClick={() => onAddCrossSell(cs)}
          >
            <div className={styles["cross-sell-left"]}>
              <Image
                src={cs.image}
                showTools={false}
                className={styles["image"]}
              />
              <div className={styles["cross-sell-description"]}>
                <p className={styles["description"]}>{cs.description}</p>
                <ColorComponent color={cs.color} className={styles["color"]} />
              </div>
            </div>
            <div>
              <Price price={cs.price} className={styles["price"]} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
