import { useLoaderData, useParams } from "react-router-dom";
import Create from "../create/create";
import { useQuery } from "react-query";
import { fetchCart } from "../../../requests/professionalCarts";
import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import DataError from "components/errors/DataError/DataError";

export default function Edit() {
  const { id } = useParams();
  const { data, isFetching, error, refetch } = useQuery(
    "cart",
    () => fetchCart(id),
    { refetchOnWindowFocus: false }
  );

  if (isFetching) {
    return <PictureLoadingSpinner />;
  }

  if (!data) {
    return <DataError error="pro-cart" />;
  }

  return <Create data={data} />;
}
