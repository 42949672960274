import { useParams } from "react-router-dom";
import Create from "../create/create";
import { useQuery } from "react-query";
import { fetchCart } from "../../../requests/carts";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import style from "./edit.module.scss";
import DataError from "components/errors/DataError/DataError";

export default function Edit() {
  const { id } = useParams();
  const { data, isFetching } = useQuery(["cart", id], () => fetchCart(id), {
    refetchOnWindowFocus: false,
  });

  if (isFetching) {
    return (
      <div className={style["loading-spinner-container"]}>
        <LoadingSpinner color="gold" />
      </div>
    );
  }

  if (!data) {
    return <DataError error="Data cart error" />;
  }

  return <Create data={data} />;
}
