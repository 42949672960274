import { useState } from "react";
import translate from "../../../../utils/translation";
import style from "./shippingModal.module.scss";
import {
  Close,
  Done,
  Launch,
  LocationOn,
  ModeEdit,
  Phone,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import {
  fetchShipping,
  updateShippingState,
} from "../../../../requests/shipping";
import { useMutation, useQuery } from "react-query";
import { IOpenModal } from "../..";
import LoadingSpinner from "../../../../components/loadingSpinner/loadingSpinner";
import { ProtectedComponent } from "../../../../components/protectedComponent/protectedComponent";
import { IProduct } from "../../../../types/logistic.types";
import { useHideSideBar } from "../../../../context/hideSideBar.context";
import { fetchLogisticFromTrackerId } from "../../../../requests/tracker";
import { Tag } from "react-bootstrap-icons";
import getStatusColor from "../../../../utils/translation/statusColor";
import LogisticModal from "../../../../components/modals/logisticModal/logisticModal";
import { createPlanningOrder } from "../../../../requests/antsroute";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../../utils/toast.options";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";

const OPTIONS_LIST = ["PENDING", "TRANSITING", "DELIVERED", "CANCELED"];

export default function ShippingModal({
  openModal,
}: {
  openModal: IOpenModal;
}) {
  const [editState, setEditState] = useState<boolean>(false);
  const { data, isLoading, error, refetch } = useQuery(
    "shipping",
    () => fetchShipping({ id: openModal.id }),
    { refetchOnWindowFocus: false, cacheTime: 0 }
  );

  const mutation = useMutation(createPlanningOrder, {
    onSuccess: () => {
      toast.success("Passage programmé", TOAST_SUCCESS_OPTIONS);
    },
    onError: () => {
      toast.error("Une erreur est survenue", TOAST_ERROR_OPTIONS);
    },
  });

  const {
    isFetching: trackerFetching,
    error: trackerError,
    data: trackerData,
  } = useQuery(
    "logistic-from-tracker",
    () => fetchLogisticFromTrackerId({ params: { id: data?.trackId } }),
    {
      refetchOnWindowFocus: false,
      enabled: data?.trackId !== undefined,
    }
  );
  const [state, setState] = useState<string>(data?.state || "PENDING");
  const { hideSide } = useHideSideBar();

  const shippingData =
    data?.direction === "OUTGOING" ? data?.recipient : data?.sender;

  if (!data || isLoading) {
    return (
      <div className={style["modal"]}>
        <LoadingSpinner color={"gold"} />
      </div>
    );
  }

  return (
    <div className={style["modal"]}>
      <div className={style["close-button"]}>
        <Close />
      </div>
      <div className={style["modal-top"]}>
        {data?.period && (
          <div className={style["time-range"]}>
            {new Date(data?.period.start || "").toLocaleTimeString("fr-FR", {
              hour: "2-digit",
              minute: "2-digit",
            })}
            {" - "}
            {new Date(data?.period.end || "").toLocaleTimeString("fr-FR", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </div>
        )}
        <div className={style["direction-container"]}>
          <div
            className={`${
              data?.direction === "OUTGOING"
                ? style["outgoing"]
                : style["incoming"]
            } 
            ${style["direction"]}`}
          >
            {translate(data?.direction || "")}
          </div>
        </div>
      </div>
      <div className={style["nom-complet"]}>
        {data?.contact?.givenname} {data?.contact?.familyname}
        <Link
          className={style["access-button"]}
          to={`/overview/${data?.trackId}`}
        >
          <Launch className={style["access-button-icon"]} />
        </Link>
      </div>

      <hr />
      <div className={style["address"]}>
        <div className={style["address-left"]}>
          <div>
            {shippingData?.address1} {shippingData?.address2}
          </div>
          <div>
            {shippingData?.zipCode} {shippingData?.city} {shippingData?.country}
          </div>
          <div className={style["address-comment"]}>
            {shippingData?.comments}
          </div>
        </div>
        <Link
          onClick={() => window.scrollTo(0, 0)}
          className={style["address-icon"]}
          to={`http://maps.google.com/?q=${shippingData?.address1} ${shippingData?.address2} ${shippingData?.zipCode} ${shippingData?.city}`}
        >
          <LocationOn />
        </Link>
      </div>
      <hr />
      <div className={style["address"]}>
        <div className={style["address-left"]}>{shippingData?.phone}</div>
        <Link
          onClick={() => window.scrollTo(0, 0)}
          className={style["address-icon"]}
          to={`tel:${data?.contact?.phone}`}
        >
          <Phone />
        </Link>
      </div>
      <hr />
      <div className={style["state"]}>
        {editState ? (
          <select
            name="field"
            className={style["select-state"]}
            onChange={({ target }) => {
              setState(target.value);
            }}
          >
            <option>{"-- choisissez un état --"}</option>
            {OPTIONS_LIST.map((value: string, index: number) => {
              return (
                <option key={index} value={value}>
                  {translate(value)}
                </option>
              );
            })}
          </select>
        ) : (
          <div className={style["select-state"]}>
            {`Status de la ${translate(
              data?.direction || ""
            ).toLocaleLowerCase()} : `}
            <div className={stateColor(data?.state || "")}>
              {translate(data?.state || "")}
            </div>
          </div>
        )}
        <ProtectedComponent roles={["ROLE_ADMIN"]}>
          <div className={style["edit-button"]} onClick={changeEditState}>
            {editState ? (
              <div
                onClick={() => updateState(state)}
                className={style["done-button"]}
              >
                <Done />
              </div>
            ) : (
              <ModeEdit />
            )}
          </div>
        </ProtectedComponent>
      </div>
      <hr />
      <ul className={style["product-list"]}>
        {`Articles (${data?.productsList?.length}) :`}
        {data?.direction === "OUTGOING" && trackerData && (
          <div className={style["workshop-id-list"]}>
            {trackerData.productsList &&
              trackerData.productsList.length &&
              trackerData.productsList.map(
                (product: IProduct, index: number) => {
                  return (
                    <ShippingProductState
                      logisticId={trackerData.id}
                      product={product}
                      index={index}
                      key={index}
                    />
                  );
                }
              )}
          </div>
        )}
        {data?.productsList &&
          data?.productsList.map((product: IProduct, key: number) => {
            return (
              <li key={key}>
                {translate(product.productGroup)} {"- "}
                {translate(product.operationGroup)}
                {product.brand ? " - " + product.brand : ""}
              </li>
            );
          })}
      </ul>
      {/* </> */}
    </div>
    // </div>
  );

  function changeEditState() {
    setEditState(!editState);
  }

  async function updateState(state: string) {
    try {
      await updateShippingState(openModal.id, state);
      refetch();
    } catch (error: any) {
      console.error(error);
    }
  }
}

export function stateColor(state: string): string {
  switch (state) {
    case "PENDING":
      return `${style["select-state"]} ${style["yellow"]}`;
    case "TRANSITING":
      return `${style["select-state"]} ${style["yellow"]}`;
    case "DELIVERED":
      return `${style["select-state"]} ${style["green"]}`;
    case "CANCELED":
      return `${style["select-state"]} ${style["red"]}`;
    default:
      return `${style["select-state"]} ${style["yellow"]}`;
  }
}

interface ShippingProductStateProps {
  logisticId: string;
  product: IProduct;
  index: number;
}

function ShippingProductState({
  logisticId,
  product,
  index,
}: ShippingProductStateProps) {
  const [showProductState, setShowProductState] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  return (
    <>
      <div
        className={style["modal-product-state-container"]}
        key={`${index}-${product.workshopId}`}
        onClick={toogleLogisticModal}
      >
        {product.status && ( //showProductState &&
          <div
            className={style["modal-product-state"]}
            style={{ backgroundColor: `${getStatusColor(product.status)}` }}
          >
            {translate(product.status)}
          </div>
        )}
        <div
          className={style["workshop-id"]}
          onClick={() => setShowProductState(!showProductState)}
        >
          {product.workshopId}
          <Tag />
        </div>
      </div>
      <ModalContainer isOpen={modalIsOpen} onCancel={toogleLogisticModal}>
        <LogisticModal id={logisticId} requiredActions={false} />
      </ModalContainer>
    </>
  );

  async function toogleLogisticModal() {
    setModalIsOpen(!modalIsOpen);
  }
}
