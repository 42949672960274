import { useState } from "react";
import styles from "../overviewActionDispatch.module.scss";
import Button from "components/Button/Button";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import LogisticModal from "components/modals/logisticModal/logisticModal";
import { ITracker } from "types/tracker.types";
import { LOGISTIC_WORKSHOP, logisticStateMap } from "utils/logistic.init";
import { useQueryClient } from "react-query";

interface PutInRepairButtonProps {
  tracker: ITracker;
}

export default function PutInRepairButton({ tracker }: PutInRepairButtonProps) {
  const queryClient = useQueryClient();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const logisticStateRank = logisticStateMap.get(tracker?.logistic?.state) || 0;

  if (logisticStateRank >= LOGISTIC_WORKSHOP) {
    return null;
  }

  return (
    <>
      <Button
        className={styles["action-button"]}
        onClick={handleOpenLogisticModal}
      >
        Mettre en réparation
      </Button>
      <ModalContainer isOpen={modalIsOpen} onCancel={handleCloseLogisticModal}>
        <LogisticModal
          id={tracker.logistic.documentId}
          requiredActions={true}
          dueDate={tracker?.outgoingShipping?.period?.start}
          onToolSelect={handleCloseLogisticModal}
        />
      </ModalContainer>
    </>
  );

  function handleOpenLogisticModal() {
    setModalIsOpen(true);
  }

  function handleCloseLogisticModal() {
    setModalIsOpen(false);
    queryClient.refetchQueries([
      "logistic-overview",
      tracker.logistic.documentId,
    ]);
    queryClient.refetchQueries(["tracker-overview", tracker.id]);
  }
}
