import { DropOffForm, ShippingServiceForm } from "types/logistic.types";
import styles from "./shippingServiceSection.module.scss";
import SectionTitle from "components/SectionTitle/SectionTitle";
import ShippingService from "./ShippingService/ShippingService";
import { useQuery } from "react-query";
import { fetchShippingMethodsList } from "requests/shippingMethod";
import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import DataError from "components/errors/DataError/DataError";

interface ShippingServiceSectionProps {
  dropOff: DropOffForm;
  onShippingServiceChange: (shippingService: ShippingServiceForm) => void;
}

export default function ShippingServiceSection({
  dropOff,
  onShippingServiceChange,
}: ShippingServiceSectionProps) {
  const { data, isFetching } = useQuery(
    "shipping-method-list-drop-off",
    () => fetchShippingMethodsList("isPublic=true"),
    { refetchOnWindowFocus: false }
  );

  if (!dropOff.productList.length) {
    return null;
  }

  if (isFetching) {
    return (
      <div className={styles["loading-section"]}>
        <PictureLoadingSpinner />
      </div>
    );
  }

  if (!data) {
    return <DataError error={"Shipping Methods"} />;
  }

  const { shippingMethodsList } = data;

  return (
    <div className={styles["shipping-service-section"]}>
      <SectionTitle
        title={"Méthode de livraison"}
        className={styles["section-title"]}
      />
      <div className={styles["shipping-service-container"]}>
        {shippingMethodsList
          .filter((sm) => sm.shippingService !== "CHRONOPOST-EUR")
          .map((ss, index) => (
            <ShippingService
              key={index}
              onShippingSelect={() =>
                onShippingServiceChange({ ...ss, name: ss.shippingService })
              }
              shippingService={{
                ...ss,
                name: ss.shippingService,
                selected: ss.shippingService === dropOff?.shippingService?.name,
              }}
            />
          ))}
      </div>
    </div>
  );
}
