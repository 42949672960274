import Input from "components/inputs/Input/Input";
import styles from "./alphabeticalListModal.module.scss";
import { DropOffProductForm } from "types/logistic.types";
import brandsList from "requests/brands.json";
import { useRef, useState } from "react";
import clsx from "clsx";
import { ALPHABET } from "utils/constants";

interface AlphabeticalListModalProps {
  selectedItem: string | null;
  onItemSelect: (item: string) => void;
  searchList: string[];
}

export default function AlphabeticalListModal({
  selectedItem,
  onItemSelect,
  searchList,
}: AlphabeticalListModalProps) {
  const itemRefs = useRef<{ [key: string]: HTMLLIElement | null }>({});
  const [selectedLetter, setSelectedLetter] = useState<string>("#");
  const [searchInput, setSearchInput] = useState<string>("");

  return (
    <div className={styles["brand-modal"]}>
      <Input
        className={styles["input"]}
        label="Recherche"
        name="brand"
        value={searchInput}
        onChange={handleInputChange}
        autoFocus
      />
      <ul className={styles["alphabet-list"]}>
        {ALPHABET.map((letter, index) => {
          return (
            <li
              className={clsx(
                styles["alphabet-item"],
                selectedLetter === letter && styles["selected"]
              )}
              key={index}
              onClick={() => handleScrollToLetter(letter)}
            >
              {letter}
            </li>
          );
        })}
      </ul>
      <ul className={styles["brand-list"]}>
        {searchList
          .filter((item) => {
            if (searchInput) {
              return item
                .toLocaleLowerCase("fr-FR")
                .includes(searchInput.toLocaleLowerCase("fr-FR"));
            }
            return true;
          })
          .sort((a, b) => a.localeCompare(b))
          .map((searchListItem, index) => {
            let firstLetter: string;
            if (parseInt(searchListItem[0].toUpperCase())) {
              firstLetter = "#";
            } else {
              firstLetter = searchListItem[0].toUpperCase();
            }
            return (
              <li
                className={clsx(
                  styles["brand-item"],
                  selectedItem === searchListItem && styles["selected"]
                )}
                key={index}
                onClick={() => onItemSelect(searchListItem)}
                ref={(el) => {
                  if (!itemRefs.current[firstLetter]) {
                    itemRefs.current[firstLetter] = el;
                  }
                }}
              >
                {searchListItem}
              </li>
            );
          })}
      </ul>
    </div>
  );

  function handleInputChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    setSearchInput(target.value);
  }

  function handleScrollToLetter(letter: string) {
    const element = itemRefs.current[letter];
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      setSelectedLetter(letter);
    }
  }
}
