import LogisticModal from "../../../../components/modals/logisticModal/logisticModal";
import WorkshopId from "../../../../components/workshopId/workshopId";
import { IProduct } from "../../../../types/logistic.types";
import { dateDiffInDays } from "../../../../utils/utils";
import style from "./productCard.module.scss";
import { fetchLogisticList } from "../../../../requests/logistic";
import { useState } from "react";
import { getOperationsListAcronym } from "../../../../utils/parseLabelOperation.function";
import State from "components/State/State";
import Image from "components/image/Image";
import clsx from "clsx";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";

interface ProductCardProps {
  product: IProduct;
  showOperationAcronym?: boolean;
  showState?: boolean;
  showDate?: boolean;
  showPicture?: boolean;
  showWorkshopId?: boolean;
  showBrand?: boolean;
  showOperations?: boolean;
  showComments?: boolean;
}

export default function ProductCard({
  product,
  showOperationAcronym = false,
  showState = false,
  showDate = true,
  showPicture = true,
  showWorkshopId = true,
  showBrand = true,
  showOperations = true,
  showComments = true,
}: ProductCardProps) {
  const operationAcronym = getOperationsListAcronym(product.operationsList);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [productIndex, setProductIndex] = useState<number>(0);
  const [logisticId, setLogisticId] = useState<string>("");
  const [dueDate, setDueDate] = useState<Date | undefined>();

  return (
    <>
      <div className={style["product-card"]} onClick={handleOpenModal}>
        {/* WORKSHOP ID */}
        <div className={style["product-card-grid"]}>
          <div
            className={clsx(style["grid-item-workshop-id"], {
              [style["hide"]]: showWorkshopId === false,
            })}
          >
            <WorkshopId
              className={style["workshop-id"]}
              workshopId={product.workshopId}
              size="small"
            />
          </div>

          {/* BRAND */}
          <div
            className={clsx(style["grid-item-brand"], {
              [style["hide"]]: showBrand === false,
            })}
          >
            <div className={style["brand"]}>{product.brand}</div>
          </div>

          {/* PICTURE */}
          <div
            className={clsx(style["grid-item-picture"], {
              [style["hide"]]: showPicture === false,
            })}
          >
            <div>
              <Image
                clickable={false}
                src={product.incomingPicturesList?.at(0)}
                imageClassName={style["picture"]}
                showTools={false}
              />
            </div>
          </div>

          {/* ACRONYMS */}
          <div
            className={clsx(style["grid-item-acronyms"], {
              [style["hide"]]: showOperationAcronym === false,
            })}
          >
            <div className={style["operations-acronym-list"]}>
              {(operationAcronym || []).map((opAcro) => {
                return (
                  <div className={style["operations-acronym"]}>{opAcro}</div>
                );
              })}
            </div>
          </div>

          {/* OPERATIONS */}
          <div
            className={clsx(style["grid-item-operations"], {
              [style["hide"]]: showOperations === false,
            })}
          >
            <ul>
              {product.operationsList.map((op) => (
                <li key={op.description}>{op.description}</li>
              ))}
            </ul>
          </div>

          {/* COMMENTS */}
          {product.comments && product.comments !== "" && (
            <div
              className={clsx(style["grid-item-comments"], {
                [style["hide"]]: showComments === false,
              })}
            >
              <i>{product.comments}</i>
            </div>
          )}

          {/* STATE */}
          <div
            className={clsx(style["grid-item-state"], {
              [style["hide"]]: showState === false,
            })}
          >
            <div className={style["state"]}>
              <State state={product.status} />
            </div>
          </div>

          {/* DATE */}
          <div
            className={clsx(style["grid-item-due-date"], {
              [style["hide"]]: showDate === false,
            })}
          >
            {product.dueDate && (
              <div className={style["due-date"]}>
                {`${new Date(product.dueDate).toLocaleDateString("fr-FR")} `}
                <span>
                  {`(${dateDiffInDays(
                    new Date(),
                    new Date(product.dueDate)
                  )} jours)`}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <ModalContainer isOpen={modalIsOpen} onCancel={handleOpenModal}>
        <LogisticModal
          id={logisticId}
          productIndex={productIndex}
          dueDate={dueDate}
          requiredActions={false}
        />
      </ModalContainer>
    </>
  );

  async function toogleLogisticModal() {
    setModalIsOpen(!modalIsOpen);
  }

  async function handleOpenModal() {
    if (modalIsOpen) {
      setModalIsOpen(false);
      return;
    }
    if (!product.trackId) {
      return;
    }
    setModalIsOpen(true);
    fetchLogisticList(`?trackId=${product.trackId}`).then((data) => {
      let logistic = data?.logisticsList.at(0);
      if (!logistic) {
        setModalIsOpen(false);
        return;
      }
      let productIndex = logistic.productsList.findIndex(
        (p) => p.workshopId === product.workshopId
      );
      //productIndex
      setLogisticId(logistic.id);
      setProductIndex(productIndex);
      setDueDate(logistic.productsList[productIndex]?.dueDate);
      setModalIsOpen(!modalIsOpen);
    });
  }
}
