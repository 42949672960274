import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Create from "../create/create";
import { fetchQuote } from "../../../requests/quote";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import DataError from "components/errors/DataError/DataError";

export default function Edit() {
  const { id } = useParams();
  const { data, isFetching, refetch } = useQuery(
    ["quote", id],
    () => fetchQuote(id),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isFetching) {
    return <LoadingSpinner color="gold" />;
  }

  if (!data) {
    return <DataError error="quote" />;
  }

  return <Create defaultQuote={data} refetch={refetch} />;
}
