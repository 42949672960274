import { Outlet, RouteObject } from "react-router-dom";

import ErrorPage from "../errorPage/error-page";
import QuickAccess from "./index";
import IncomingShipping from "./incomingShipping/incomingShipping";
import ProShipping from "./proShipping/proShipping";
import ProIncomingShipping from "./proShipping/proIncomingShipping/proIncomingShipping";
import ProOutgoingShipping from "./proShipping/proOutgoingShipping/proOutgoingShipping";
import Climbing from "./workshop/climbing/Climbing";
import Workshop from "./workshop/Workshop";
import Cobbler from "./workshop/cobbler/Cobbler";
import Leather from "./workshop/leather/Leather";
import Barcode from "./barcode/barcode";
import IncomingShippingCheckIn from "./incomingShipping/incomingShippingCheckIn/IncomingShippingCheckIn";
import InStoreDropOff from "./inStoreDropOff/InStoreDropOff";

export const route: RouteObject = {
  path: "/quick-access",
  element: <Outlet />,
  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <QuickAccess />,
    },
    {
      path: "incoming-shipping",
      element: <IncomingShipping />,
    },
    {
      path: "incoming-shipping/:trackId",
      element: <IncomingShippingCheckIn />,
    },
    {
      path: "in-store-drop-off",
      element: <InStoreDropOff />,
    },
    {
      path: "pro-shipping",
      element: <ProShipping />,
    },
    {
      path: "pro-incoming-shipping/:shopId",
      element: <ProIncomingShipping />,
    },
    {
      path: "pro-outgoing-shipping/:shopId",
      element: <ProOutgoingShipping />,
    },
    {
      path: "workshop",
      element: <Workshop />,
    },
    {
      path: "workshop/climbing",
      element: <Climbing />,
    },
    {
      path: "workshop/cobbler",
      element: <Cobbler />,
    },
    {
      path: "workshop/leather",
      element: <Leather />,
    },

    {
      path: "barcode",
      element: <Barcode />,
    },
  ],
};
