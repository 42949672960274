import { useQuery } from "react-query";
import styles from "./shippingList.module.scss";
import { fetchShippingList } from "requests/shipping";
import LoadingSpinner from "components/loadingSpinner/loadingSpinner";
import DataError from "components/errors/DataError/DataError";
import { IShipping } from "types/shipping.types";
import IncomingShippingCard from "./IncomingShippingCard/IncomingShippingCard";

interface ShippingListProps {
  date: Date;
}

export default function ShippingList({ date }: ShippingListProps) {
  const { data, isFetching } = useQuery(
    ["shippings-list", date],
    () => fetchShippingList(formatedQuery()),
    { refetchOnWindowFocus: false, keepPreviousData: true }
  );

  if (isFetching) {
    return (
      <div className={styles["loading-spinner-container"]}>
        <LoadingSpinner color="gold" />
      </div>
    );
  }

  if (!data) {
    return <DataError error="Shipping" />;
  }

  const { shippingsList } = data;

  return (
    <div className={styles["shipping-selection-list"]}>
      {shippingsList.map((shipping: IShipping, index: number) => (
        <IncomingShippingCard key={index} shipping={shipping} />
      ))}
    </div>
  );

  function formatedQuery() {
    return `?period.start=${date.toISOString()}&period.start=${date.toISOString()}&shippingMethod.shippingService=INTERNAL&direction=INCOMING&state=PENDING`;
  }
}
