import CobblerWidget from "components/widget/Workshop/cobbler/CobblerWidget";
import style from "./workshop.module.scss";
import LeatherWidget from "components/widget/Workshop/leather/LeatherWidget";
import ClimbingWidget from "components/widget/Workshop/climbing/ClimbingWidget";

export default function Workshop() {
  return (
    <div className={style["workshop"]}>
      <div className={style["workshop-grid"]}>
        <CobblerWidget />
        <LeatherWidget />
        <ClimbingWidget />
      </div>
    </div>
  );
}
