import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import styles from "./customerTrackersList.module.scss";
import { useQuery } from "react-query";
import {
  fetchCustomer,
  fetchCustomerWithTrackersList,
} from "requests/customer";
import DataError from "components/errors/DataError/DataError";
import LinkTrackerToDropOff from "./LinkTrackerToDropOff/LinkTrackerToDropOff";
import SectionTitle from "components/SectionTitle/SectionTitle";
import { DropOffForm } from "types/logistic.types";
import { COLLECTED, trackerStateMap } from "utils/tracking.utils";
import { ITracker } from "types/tracker.types";

interface CustomerTrackersListProps {
  dropOff: DropOffForm;
  onClose: () => void;
}

export default function CustomerTrackersList({
  dropOff,
  onClose,
}: CustomerTrackersListProps) {
  const { data, isLoading } = useQuery(
    ["customer-tracker-list", dropOff],
    () => fetchCustomerWithTrackersList(dropOff.contact?.id),
    {
      enabled: Boolean(dropOff.contact?.id),
    }
  );

  if (!dropOff.contact?.id) {
    return null;
  }

  if (isLoading) {
    return (
      <div className={styles["loading-section"]}>
        <PictureLoadingSpinner />
      </div>
    );
  }

  if (!data) {
    return (
      <div className={styles["customer-trackers-list"]}>
        <DataError error="Customer not found" />
      </div>
    );
  }
  const { trackers } = data;

  if (!trackers) {
    return (
      <div className={styles["customer-trackers-list"]}>
        <p>aucune commande disponible</p>
      </div>
    );
  }

  return (
    <>
      <SectionTitle
        title="Liste des commandes en cours"
        className={styles["section-title"]}
      />
      <div className={styles["customer-trackers-list"]}>
        <table className={styles["trackers-table"]}>
          <tbody className={styles["trackers-list"]}>
            {trackers.sort(sortByStateRank).map((tracker, index) => (
              <LinkTrackerToDropOff
                key={index}
                tracker={tracker}
                onClose={onClose}
                isCurrent={tracker.id === dropOff.trackId}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );

  function sortByStateRank(a: ITracker, b: ITracker) {
    const rankA = trackerStateMap.get(a.state) || 0;
    const rankB = trackerStateMap.get(b.state) || 0;

    if (rankA < rankB) {
      return -1;
    }
    if (rankA > rankB) {
      return 1;
    }
    return 0;
  }
}
