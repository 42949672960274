import Create from "../create/Create";
import { useParams } from "react-router-dom";
import {
  fetchQuoteFromTrackerId,
  loadShipping,
} from "../../../requests/tracker";

import { GP_SHIPPING_ADDRESS } from "../../../utils/shipping.init";
import { useQuery } from "react-query";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import DataError from "components/errors/DataError/DataError";

export default function Generate() {
  const { trackerId, direction } = useParams();
  const { data: quote, isLoading: quoteIsLoading } = useQuery(
    ["quote", trackerId],
    () => fetchQuoteFromTrackerId(trackerId || ""),
    { enabled: Boolean(trackerId) }
  );
  const { data, isLoading: shippingIsLoading } = useQuery(
    ["generate-shipping", trackerId, direction],
    () => loadShipping(direction, trackerId),
    { enabled: Boolean(trackerId) && Boolean(direction) }
  );

  if (shippingIsLoading || quoteIsLoading) {
    return <LoadingSpinner />;
  }

  if (!data) {
    return <DataError error="generate-shipping" />;
  }

  const { shipping } = data;

  if (!shipping || !quote) {
    return <DataError error="generate-shipping" />;
  }

  if (!shipping.period) {
    shipping.period = { start: new Date(), end: new Date() };
  }
  if (shipping.direction === "INCOMING" && !shipping.recipient) {
    shipping.recipient = GP_SHIPPING_ADDRESS;
  } else if (shipping.direction === "OUTGOING" && !shipping.sender) {
    shipping.sender = GP_SHIPPING_ADDRESS;
  }

  return (
    <Create
      shipping={shipping}
      dueDate={getDateAfterDays(quote.maximumDurationDays)}
    />
  );

  function getDateAfterDays(days?: number): Date | undefined {
    if (shipping?.direction === "INCOMING") {
      return undefined;
    }
    if (!days) {
      return undefined;
    }
    let currentDate = new Date();
    let addedDays = 0;
    while (addedDays < days) {
      currentDate.setDate(currentDate.getDate() + 1);
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        addedDays++;
      }
    }
    return currentDate;
  }
}
