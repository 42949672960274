import { useState } from "react";
import { ITracker } from "../../../../../types/tracker.types";
import OverviewSection from "../OverviewSection/OverviewSection";
import OverviewSectionHeader from "../OverviewSectionHeader/OverviewSectionHeader";
import styles from "./commentSection.module.scss";
import Button from "../../../../../components/Button/Button";
import { Close } from "@mui/icons-material";
import clsx from "clsx";
import { isDesktop } from "react-device-detect";
import { updateTrackerNotes } from "../../../../../requests/tracker";
import { useMutation, useQueryClient } from "react-query";
import { STD_ERROR_MESSAGE } from "../../../../../utils/constants";
import { toast } from "react-toastify";
import { useAuth } from "../../../../../context/auth.context";
import Timeline from "../../../../../components/Timeline/Timeline";

interface CommentSectionProps {
  tracker: ITracker;
}

export default function CommentSection({ tracker }: CommentSectionProps) {
  const queryClient = useQueryClient();
  const [showAll, setShowAll] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const { username } = useAuth();
  const mutation = useMutation(updateTrackerNotes, {
    onSuccess: () => {
      queryClient.refetchQueries(["tracker-overview", tracker.id]);
    },
    onError: () => {
      toast.error(STD_ERROR_MESSAGE);
    },
  });

  return (
    <OverviewSection className={styles["comment-section"]}>
      <OverviewSectionHeader
        title={`Notes (${tracker?.notes?.length || "0"})`}
        onClick={handleToggleShowAll}
        expanded={showAll}
      >
        <div
          className={clsx(
            styles["edit-button"],
            isDesktop ? styles["hover"] : styles["active"]
          )}
          onClick={handleToggleEdit}
        >
          {edit ? <Close /> : <Button>Ajouter une note</Button>}
        </div>
      </OverviewSectionHeader>
      {edit && (
        <form className={styles["note-section"]} onSubmit={handleAddNote}>
          <textarea
            className={styles["comment-area"]}
            value={comment}
            onChange={handleOnCommentChange}
            placeholder="commentaire..."
          />
          <Button type="submit">Valider</Button>
        </form>
      )}
      <Timeline
        showAll={showAll}
        notes={tracker.notes || []}
        onRemove={handleRemoveNote}
      />
    </OverviewSection>
  );

  function handleToggleShowAll() {
    setShowAll(!showAll);
  }

  async function handleRemoveNote(index: number) {
    if (!tracker.notes) {
      return;
    }
    if (tracker.notes.length == 1 && index == 0) {
      return mutation.mutate({
        trackerId: tracker.id,
        notes: [],
      });
    }

    mutation.mutate({
      trackerId: tracker.id,
      notes: tracker.notes.filter((_, i) => i !== index) || [],
    });
  }

  async function handleAddNote(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const newNotes = [
      ...(tracker.notes || []),
      {
        timeStamp: new Date(),
        body: comment,
        user: username || "Utilisateur inconnu",
      },
    ];
    mutation.mutate({ trackerId: tracker.id, notes: newNotes });
  }

  async function handleToggleEdit() {
    setEdit(!edit);
  }

  function handleOnCommentChange({
    target,
  }: {
    target: EventTarget & HTMLTextAreaElement;
  }) {
    setComment(target.value);
  }
}
